import { Add } from '@mui/icons-material'
import { FormControl } from '@mui/material'
import React from 'react'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

export interface AssignCreditFormData
{
	userId: string
}

interface Props
{
	onSubmit: SubmitHandler<AssignCreditFormData>
	onCancel: () => void
	loading?: boolean
}

const Page : React.FC<Props> = ({ onSubmit, onCancel, loading }) => {
	const formMethods = useForm<AssignCreditFormData>()

	return (
		<FormProvider {...formMethods}>
		    <FormControl id='assigncredit-form'  fullWidth component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
                <BOOTextInput
                    id='userid-input'
                    formField='userId'
					formValidation={{ required: true }}
                    placeholder='xxx-aaa-yyy'
                    label='UserId'
                />
                <div className='flex mt-5 justify-end'>
                    <BOOButton type='submit' variant='contained' style={{ marginRight: '5px' }} loading={loading ?? false}>
                        <Add />
                        Credit User
                    </BOOButton>
                    <BOOButton variant='outlined' onClick={onCancel} disabled={loading ?? false}>
                        Cancel
                    </BOOButton>
                </div>
            </FormControl>
        </FormProvider>
	)
}
export default Page
