import { useCallback } from 'react'
import { AdminApi, WithdrawalRequestModel } from 'boorio-portal-commons/api'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useApi } from 'utils/api/useApi'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../AppStore'
import { updateWithdrawal, refreshWithdrawals } from '../features/Withdrawals'

export const useRefreshWithdrawals = () => {
	const withdrawals = useSelector((state: RootState) => state.withdrawals.withdrawals)
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const api = useApi(AdminApi)

	const refresh = useCallback(async () =>
    {
		try {
			const withdrawals = (await api.withdrawalsGetAll()).data
			dispatch(refreshWithdrawals(withdrawals))
		} catch (e: any) {
			showAlert({ type: 'error', message: e?.message ?? e })
		}
    },
    [api])

	return { withdrawals, refreshWithdrawals: refresh }
}

export const useGetWithdrawalById = (id: string): WithdrawalRequestModel | undefined => {
	const withdrawals = useSelector((state: RootState) => state.withdrawals.withdrawals)
	return withdrawals.find((it) => it.id === id)
}

export const useApproveWithdrawal = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (id: string) =>
    {
        const withdrawal = (await api.withdrawalsApprove(id)).data
        dispatch(updateWithdrawal(withdrawal))		
	},
    [api])
    
	return action
}
export const useDenyWithdrawal = () => {
    const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (id: string) =>
    {
        const withdrawal = (await api.withdrawalsDeny(id)).data
        dispatch(updateWithdrawal(withdrawal))
    },
    [api])

	return action
}