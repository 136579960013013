export interface KnownBOOErrors {
	emailVerificationRequired: string
}

export const ERRORS: KnownBOOErrors = {
	emailVerificationRequired: 'Unauthorized to access protected content. Email verification required.',
}
function findKeyForValue(obj: KnownBOOErrors, value: string): keyof KnownBOOErrors | undefined {
	return Object.keys(obj).find((key) => (obj as unknown as Record<string, string>)[key] === value) as keyof KnownBOOErrors | undefined
}

export type KnownBOOErrorType = keyof KnownBOOErrors

export interface BOOError {
	message: string
	type?: KnownBOOErrorType
}

export function getBOOError(error: any) : BOOError
{
	console.log(JSON.stringify(error, null, 4))
	const message = error?.response?.data?.error ?? error?.message ?? JSON.stringify(error)
	let type: KnownBOOErrorType | undefined

	if (message && Object.values(ERRORS).includes(message)) {
		type = findKeyForValue(ERRORS, message)
	}
	return { message, type }
}

// const message = err.response.data.error
