import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import ChangeNameForm, { ChangeNameFormData } from './ChangeNameForm'

export type ChangeNameViewProps = {
	room: PokerGameModel,
	clickSubmit: SubmitHandler<ChangeNameFormData>
} & CommonModalProps

export const ChangeNameView : React.FC<ChangeNameViewProps> = ({ id, room, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<ChangeNameForm room={room} loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}