import React, { FC, useMemo } from 'react'
import _ from 'lodash'
import { Routes } from 'react-router-dom'
import '../index.scss'
import BOOAppBar from '../views/NavBar'
import { useIsLoggedIn } from '../stores/hooks/useActiveUser'
import { useRedirectUnauthorized } from '../utils/redirects/useRedirectUnauthorized'
import { BOORoutes } from '../utils/Navigation'
import { useRedirectLoggedIn } from '../utils/redirects/useRedirectLoggedIn'
import { ReduxAlertStack } from '../views/Alerts/ReduxAlertStack'

const RootPage: FC<{}> = () => {
	const isLoggedIn = useIsLoggedIn()

	const routes = useMemo(() => BOORoutes(), [])

	useRedirectUnauthorized()
	useRedirectLoggedIn()

	const navBar = useMemo(() => {
		return isLoggedIn ? <BOOAppBar /> : undefined
	}, [isLoggedIn])

	return (
		<>
			<ReduxAlertStack />
			{navBar}
			<Routes>{routes}</Routes>
		</>
	)
}

export default RootPage
