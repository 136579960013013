import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import { BOOConfirmationModal } from 'views/Modal/BOOConfirmationModal'

export type RoomKickPlayersViewProps = {
	room: PokerGameModel,
	clickSubmit: SubmitHandler<PokerGameModel>
} & CommonModalProps

export const RoomKickPlayersView : React.FC<RoomKickPlayersViewProps> = ({ id, room, clickSubmit, clickOpen }) =>
{
	return (
		<BOOConfirmationModal
			id={id}
			title='Confirm Kick All'
			description='Do you want to kick all the players? All the money they have put on the table will be refunded to their wallets!'
			okTitle='KICK ALL'
			cancelTitle='Cancel'
			onClickOk={() => clickSubmit(room)}
			onDismiss={() => clickOpen(false)}
		/>
	)
}