import { useCallback } from 'react'
import { AdminApi, ConversionTransactionModel, CreditTransactionModel } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../AppStore'
import { updateBalance, updateTransactionsUnknown, updateDepositsIgnored, updateConversionsUnknown, updateCreditsUnknown } from '../features/Crypto'

export const useRefreshBalance = () => {
	const balance = useSelector((state: RootState) => state.crypto.balance)
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const api = useApi(AdminApi)

	const refresh = useCallback(async () =>
    {
		try {
			const balance = (await api.adminPaymentsGetBalance()).data
			dispatch(updateBalance(balance))
		} catch (e: any) {
			showAlert({ type: 'error', message: e?.message ?? e })
		}
    },
    [api])

	return { balance, refreshBalance: refresh }
}

export const useUnknownTransactions = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const transactionsUnknown = useSelector((root: RootState) => root.crypto.transactionsUnknown)

	const action = useCallback(async () =>
    {
		api.adminPaymentsGetTransactionsUnknown()
        .then((res) => {
			dispatch(updateTransactionsUnknown(res.data))
		})
    },
    [api])

	return { transactionsUnknown, refreshTransactionsUnknown: action }
}

export const useDepositsIgnored = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const depositsIgnored = useSelector((root: RootState) => root.crypto.depositsIgnored)

	const action = useCallback(async () =>
    {
		api.adminPaymentsGetDepositsIgnored()
        .then((res) => {
			dispatch(updateDepositsIgnored(res.data))
		})
    },
    [api])

	return { depositsIgnored, refreshDepositsIgnored: action }
}

export const useConversionsUnknown = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const conversionsUnknown = useSelector((root: RootState) => root.crypto.conversionsUnknown)

	const action = useCallback(async () =>
    {
		api.adminPaymentsGetConversionsUnknown()
        .then((res) => {
			dispatch(updateConversionsUnknown(res.data))
		})
    },
    [api])

	return { conversionsUnknown, refreshConversionsUnknown: action }
}

export const useCreditsUnknown = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const creditsUnknown = useSelector((root: RootState) => root.crypto.creditsUnknown)

	const action = useCallback(async () =>
    {
		api.adminPaymentsGetCreditsUnknown()
        .then((res) => {
			dispatch(updateCreditsUnknown(res.data))
		})
    },
    [api])

	return { creditsUnknown, refreshCreditsUnknown: action }
}

export const useResetRake = () => {
    const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async () =>
    {
        await api.adminPaymentsTransferRakeOut()
        const balance = (await api.adminPaymentsGetBalance()).data
        dispatch(updateBalance(balance))
    },
    [api])

	return action
}

export const useRetryConversion = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (conversion: ConversionTransactionModel) =>
    {
		await api.adminPaymentsRetryConversion({ value: conversion.id })

		api.adminPaymentsGetConversionsUnknown()
        .then((res) => {
			dispatch(updateConversionsUnknown(res.data))
		})
	},
    [api])
    
	return action
}

export const useAssignCredit = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (credit: CreditTransactionModel, userId: string) =>
    {
		await api.adminPaymentsAssignCreditToUser(userId, { value: credit.id })

		api.adminPaymentsGetCreditsUnknown()
        .then((res) => {
			dispatch(updateCreditsUnknown(res.data))
		})
	},
    [api])
    
	return action
}