import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminApi, UserLevelRequirementModel } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { RootState } from '../AppStore'
import { refreshLevelRequirements, updateLevelRequirement } from '../features/LevelRequirements'

export const useLevelRequirements = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const levels = useSelector((root: RootState) => root.levelRequirements.levels)

	const action = useCallback(async () =>
    {
		api.levelRequirementsGetAll()
        .then((res) => {
			dispatch(refreshLevelRequirements(res.data))
		})
    },
    [api])

	return { levels, refreshLevels: action }
}

export const useUpdateLevelRequirement = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (level: number, levelRequirement: UserLevelRequirementModel) =>
    {
        const newLevelRequirement = (await api.levelRequirementsUpdate(level, levelRequirement)).data
        dispatch(updateLevelRequirement(newLevelRequirement))
	},
	[api])

	return action
}