import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PokerGameRecordModel } from 'boorio-portal-commons/api'

export interface GameRecordsStore {
	gameRecords: PokerGameRecordModel[]
}

const initialState: GameRecordsStore = {
    gameRecords: []
}

export const cryptoSlice = createSlice({
	name: 'GameRecords',
	initialState,
	reducers: {
		updateGameRecords: (state, action: PayloadAction<PokerGameRecordModel[]>) => {
			state.gameRecords = action.payload
		},
	},
})

export const { updateGameRecords } = cryptoSlice.actions

export default cryptoSlice.reducer
