import { Add, Remove, Stop, Visibility, VisibilityOff } from '@mui/icons-material'
import React, { useMemo } from 'react'
import { BOOButton, SubSectionHeader, JsonView } from 'boorio-portal-commons/components'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { RoomDeleteView, RoomDeleteViewProps } from 'pages/Rooms/Detail/Delete/RoomDeleteView'
import { ChangeNameView, ChangeNameViewProps } from 'pages/Rooms/Detail/ChangeName/ChangeNameView'
import { PokerGameSNGStatusTypeUtils } from 'boorio-portal-commons/model/PokerGameSNGStatusTypeUtils'
import { RoomKickPlayersView, RoomKickPlayersViewProps } from 'pages/Rooms/Detail/KickPlayers/RoomKickPlayersView'
import { SNGAddBotView, SNGAddBotViewProps } from '../AddBot/SNGAddBotView'
import { SNGRunningGameView } from './SNGRunningGameView'
import { PlayerView } from '../../Rooms/Detail/PlayerView'
import { WaitingUserView } from '../../Rooms/Detail/WaitingUserView'
import { SNGHeader } from './SNGHeader'

export interface SNGPageViewProps {
	room: PokerGameModel,
	clickRefresh: () => void,
	isRefreshing: boolean,
	
	changeHidden: (hidden: boolean) => void,
	addBotProps: SNGAddBotViewProps,
	changeNameProps: ChangeNameViewProps,
	roomKickPlayersProps: RoomKickPlayersViewProps,
	roomDeleteProps: RoomDeleteViewProps
}

export const SNGPageView : React.FC<SNGPageViewProps> = ({ room, clickRefresh, isRefreshing, changeHidden, addBotProps, changeNameProps, roomKickPlayersProps, roomDeleteProps }) =>
{
    const actions = useMemo(() => {
        return (
            <>
                <BOOButton variant='contained' onClick={() => addBotProps.clickOpen(true)}>
                    <Add />
                    &nbsp;Add Bot
                </BOOButton>
				<BOOButton variant='contained' onClick={() => changeNameProps.clickOpen(true)} style={{ marginLeft: '10px' }}>
					&nbsp;Change Name
				</BOOButton>
                <BOOButton variant='contained' onClick={() => roomKickPlayersProps.clickOpen(true)} color='error' style={{ marginLeft: '10px' }}>
                    <Stop />
                    &nbsp;Kick + Refund
                </BOOButton>
                <BOOButton variant='contained' onClick={() => changeHidden(!room.status.hidden)} color='info' style={{ marginLeft: '10px' }}>
                    {room.status.hidden ? <><Visibility />&nbsp;Unhide</> : <><VisibilityOff />&nbsp;Hide</>}
                </BOOButton>
                <BOOButton variant='contained' onClick={() => roomDeleteProps.clickOpen(true)} color='error' style={{ marginLeft: '10px' }}>
                    <Remove />
                    &nbsp;Delete
                </BOOButton>
            </>
        )
    }, [room.status.hidden])

	const players = useMemo(() => {
        return room?.players?.map((it) => <PlayerView key={it.profile.userId} player={it} />)
    }, [room?.players])
    const participants = useMemo(() => {
        return room?.waitingUsers?.map((it) => <WaitingUserView key={it.profile.userId} user={it} />)
    }, [room?.waitingUsers])
    const running = useMemo(() => {
        return room && room.sngData && PokerGameSNGStatusTypeUtils.IsRunning(room.sngData!.status)
    }, [room?.sngData?.status])

	return (
		<>
            {room && <SNGHeader room={room} clickRefresh={clickRefresh} isRefreshing={isRefreshing} /> }
			<SubSectionHeader title='Actions' />
            <div id='actions' className='section'>{actions}</div>

            {running && <SubSectionHeader title='Running Game'/>}
            {running && <div className='boo-list'><SNGRunningGameView game={room}/></div>}
            
            <SubSectionHeader title='Players'/>
			<div id='player-list' className='boo-list'>{players}</div>

            <SubSectionHeader title='Participants'/>
			<div id='participants-list' className='boo-list'>{participants}</div>

            <SubSectionHeader title='Raw'/> 
			{room && <JsonView name='Room' object={room} />}

			<SNGAddBotView {...addBotProps} />
			<ChangeNameView {...changeNameProps} />
			<RoomKickPlayersView {...roomKickPlayersProps} />
			<RoomDeleteView {...roomDeleteProps} />
		</>
	)
}
