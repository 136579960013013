import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PokerGameModel } from 'boorio-portal-commons/api'

export interface Rooms {
	rooms: PokerGameModel[]
}

const initialState: Rooms = {
	rooms: [],
}

export const roomsSlice = createSlice({
	name: 'Rooms',
	initialState,
	reducers: {
		refreshRooms: (state, action: PayloadAction<PokerGameModel[]>) => {
			state.rooms = action.payload
		},
		updateRoom: (state, action: PayloadAction<PokerGameModel>) => {
			state.rooms = state.rooms.filter((it) => it.id !== action.payload.id)
			state.rooms = [...state.rooms, action.payload]
		},
	},
})

export const { refreshRooms, updateRoom } = roomsSlice.actions

export default roomsSlice.reducer
