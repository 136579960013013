import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

export interface LevelRequirementsHeaderProps {
	clickRefresh: () => void
	isRefreshing?: boolean
}

export const LevelRequirementsHeader: React.FC<LevelRequirementsHeaderProps> = ({ clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader title='Level Requirements'>
            <RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
        </SectionHeader>
	)
}