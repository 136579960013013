import React, { useEffect, useState } from 'react'
import { PokerGameFormatType, PokerGameModel } from 'boorio-portal-commons/api'
import { useRefreshRooms } from '../../stores/hooks/roomHooks'
import { useNavigateWrapper } from '../../utils/Navigation'
import { SNGsListHeader } from './SNGsListHeader'
import { SNGsListItem } from './SNGsListItem'
import { SNGsHeader } from './SNGsHeader'
import '../../index.scss'

export default () => {
	const navigate = useNavigateWrapper()
	const { rooms, refresh } = useRefreshRooms(PokerGameFormatType.Sng)
	const [isRefreshing, setRefreshing] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)
		refresh().finally(() => {
			setRefreshing(false)
		})
	}

	useEffect(() => {
		clickRefresh()
	}, [])

	const clickRoom = (room: PokerGameModel) => {
		navigate(`/sngs/${room.id}`)
	}

	const clickAdd = () => {
		navigate('/sngs/create')
	}

	const items = rooms.map((room) => <SNGsListItem key={room.id} room={room} clickRoom={clickRoom} />)

	return (
		<>
			<SNGsHeader clickRefresh={clickRefresh} clickAddSNG={clickAdd} isRefreshing={isRefreshing} sngsCount={rooms.length} />
			<div className='boo-list'>
				<SNGsListHeader />
				{items}
			</div>
		</>
	)
}
