import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import ConfirmDeleteForm, { ConfirmDeleteFormData } from 'pages/Users/ConfirmDelete/ConfirmDeleteForm'
import { CommonModalProps } from 'views/Modal/CommonModalProps'

export type ConfirmDeleteViewProps = {
	clickSubmit: SubmitHandler<ConfirmDeleteFormData>
} & CommonModalProps

export const ConfirmDeleteView : React.FC<ConfirmDeleteViewProps> = ({ id, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<ConfirmDeleteForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}