import React, { useState } from 'react'
import { CreditTransactionModel } from 'boorio-portal-commons/api'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import { RedoRounded } from '@mui/icons-material'

export interface CryptoCreditListItemProps {
	credit: CreditTransactionModel,
	clickAssignCredit: (credit: CreditTransactionModel) => void
}

export const CryptoCreditListItem: React.FC<CryptoCreditListItemProps> = ({ credit, clickAssignCredit }) =>
{
	const [focused, setFocused] = useState(false)

	return (
		<div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
			<div className='boo-list-info'>
				<p className='index'>{credit.id}</p>
				<a href={`https://bscscan.com/tx/${credit.cryptoTransaction?.hash}`}><p className='wide'>{credit.cryptoTransaction?.hash}</p></a>
				<a href={`https://bscscan.com/address/${credit.depositFromAddress}`}><p className='normal'>{credit.depositFromAddress}</p></a>
				{ credit.cryptoTransaction?.tokenContractAddress ? <a href={`https://bscscan.com/token/${credit.cryptoTransaction!.tokenContractAddress!}`}><p className='normal'>{credit.cryptoTransaction?.tokenSymbol}</p></a> : <p className='normal'>{credit.cryptoTransaction?.tokenSymbol}</p> }
				<p className='normal'>{credit.cryptoTransaction?.value}</p>
				<p className='normal'>{credit.cryptoTransaction?.status === true ? 'TRUE' : 'FALSE'}</p>
				<p className='normal'>{credit.depositTokenSymbol}</p>
				<p className='normal'>{credit.depositValue}</p>
                <div className='spacer' />
            </div>
			<Fade in={focused}>
				<div className='boo-list-options'>
					<BOOButton onClick={() => clickAssignCredit(credit)}>
						<RedoRounded />
						&nbsp;Assign
					</BOOButton>
				</div>
			</Fade>
        </div>
	)
}