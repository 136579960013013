import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AdminApi } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { RootState } from '../AppStore'
import { refreshMessage, refreshLocalisations } from '../features/Admin'

export const useRefreshAdminMessage = () => {
	const adminMessage = useSelector((state: RootState) => state.admin.message)
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const api = useApi(AdminApi)

	const action = useCallback(async () =>
    {
        try {
            const message = (await api.adminConfigGetAdminMessage()).data
            dispatch(refreshMessage(message))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    },
    [api])
	const actionInvalidate = useCallback(async () =>
    {
        try {
            const message = (await api.adminConfigInvalidateAdminMessage()).data
            dispatch(refreshMessage(message))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    },
    [api])
	const actionAdd = useCallback(async (text: string) =>
    {
        try {
            const message = (await api.adminConfigAddAdminMessage({ value: text })).data
            dispatch(refreshMessage(message))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    },
    [api])

	return { adminMessage, refreshAdminMessage: action, invalidateAdminMessage: actionInvalidate, addAdminMessage: actionAdd }
}

export const useRefreshLocalisations = () => {
	const localisations = useSelector((state: RootState) => state.admin.localisations)
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const api = useApi(AdminApi)

	const action = useCallback(async () =>
    {
        try {
            const localisations = (await api.adminConfigGetLocalisations()).data
            dispatch(refreshLocalisations(localisations))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    },
    [api])
	const actionUpdate = useCallback(async (key: string, value: string) =>
    {
        try {
            const localisations = (await api.adminConfigUpdateLocalisation(key, { value })).data
            dispatch(refreshLocalisations(localisations))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    },
    [api])

	return { localisations, refreshLocalisations: action, updateLocalisation: actionUpdate }
}