import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import { BOOConfirmationModal } from 'views/Modal/BOOConfirmationModal'

export type RoomDeleteViewProps = {
	room: PokerGameModel,
	clickSubmit: SubmitHandler<PokerGameModel>
} & CommonModalProps

export const RoomDeleteView : React.FC<RoomDeleteViewProps> = ({ id, room, clickSubmit, clickOpen }) =>
{
	return (
		<BOOConfirmationModal
			id={id}
			title={`Delete room ${room!.id}`}
			description={`Do you want to delete room ${room!.config.roomName} ?`}
			okTitle='Yes, DELETE'
			cancelTitle='Cancel'
			onClickOk={() => clickSubmit(room)}
			onDismiss={() => clickOpen(false)}
		/>
	)
}