import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#3BB2E5',
		},
		secondary: {
			main: '#AA52F4',
		},
		background: {
			default: '#000000',
			paper: '#424242',
		},
	},
})

// Typescript augmentation of Theme to add additional props
// declare module '@mui/material/styles' {
//     interface Theme {
//       status: {
//         danger: string;
//       };
//     }
//     // allow configuration using `createTheme`
//     interface ThemeOptions {
//       status?: {
//         danger?: string;
//       };
//     }
//   }
