import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

// TODO: make a standard header
export interface OtherPageHeaderProps {
	isBEUpdatePending: boolean,
	clickRefresh: () => void
	isRefreshing: boolean
}

export const OtherPageHeader: React.FC<OtherPageHeaderProps> = ({ isBEUpdatePending, clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing

	return (
        <SectionHeader
            title='Other Functions'
			subtitle={`${isBEUpdatePending ? 'BE UPDATE PENDING' : ''}`}
        >
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}