import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Select } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { BOOButton, SectionHeader, BOOTextInput } from 'boorio-portal-commons/components'
import { AdminApi, PokerGameFormatType, PokerGameType } from 'boorio-portal-commons/api'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import MathUtils from 'boorio-portal-commons/utils/MathUtils'
import { getBOOError, MCSS } from 'boorio-portal-commons'
import { useApi } from 'utils/api/useApi'
import { muiTheme } from '../../../utils/mui/theme'
import { useNavigateWrapper } from '../../../utils/Navigation'
import './CreateSNGPage.scss'

type CreateSNGFormData = {
	type: string
	roomName: string
	restartAfterFinished: boolean
	requiredLevel: number
	players: number
	costAmount: string
    prizes1: string
    prizes2?: string
    prizes3?: string
	startingSmallBlindAmount: string
	startingBigBlindAmount: string
	maximumSmallBlindAmount: string
	gamesToDoubleBlinds: number
	anteAmount: string
	startingChipsAmount: string
    isPotLimit: boolean
	timeToAct: number
	timeToMuckReveal: number
	tipDealerMultiplierAmount: string
	tableBackgroundImage: string
	tableMainImage: string
}

const formStyle : MCSS = {
	form: {
		margin: 'auto',
		background: muiTheme.palette.background.paper,
		paddingLeft: '30px',
		paddingRight: '30px',
		paddingTop: '20px',
		paddingBottom: '20px',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '8px',
		maxWidth: '1000px',
	},
	textField: {
		marginBottom: '10px',
	},
	submitButton: {
		marginTop: '30px',
		// alignSelf: 'center',
	},
	label: {
		color: 'white'
	}
}

const Form: React.FC = () => {
	const { showAlert } = useReduxAlerts()
	const navigate = useNavigateWrapper()
	const api = useApi(AdminApi)
	const [loading, setLoading] = useState(false)
	const formMethods = useForm<CreateSNGFormData>({
		defaultValues: {
			type: PokerGameType.Holdem,
			restartAfterFinished: false,
			requiredLevel: 1,
			players: 3,
            costAmount: '10.0',
            prizes1: '5.0',
            prizes2: '3.0',
			startingSmallBlindAmount: '1.0',
			startingBigBlindAmount: '2.0',
			maximumSmallBlindAmount: '512.0',
            gamesToDoubleBlinds: 6,
			anteAmount: '0.0',
			startingChipsAmount: '1000.0',
            isPotLimit: false,
			timeToAct: 30,
			timeToMuckReveal: 5,
			tipDealerMultiplierAmount: '0.05',
            tableBackgroundImage: '1',
            tableMainImage: '1',
		},
	})
	const typeWatch = formMethods.watch('type')
	const players = formMethods.watch('players')
	const costAmount = formMethods.watch('costAmount')
	const prizes1 = formMethods.watch('prizes1')
	const prizes2 = formMethods.watch('prizes2')
	const prizes3 = formMethods.watch('prizes3')
	const prizes2Normalized = useMemo(() => { 
		if (prizes2 && prizes2 !== 'NaN'){
			return prizes2
		}
		return '0'
	 }, [prizes2])
	const prizes3Normalized = useMemo(() => { 
		if (prizes3 && prizes3 !== 'NaN'){
			return prizes3
		}
		return '0'
	 }, [prizes3])
	const rakeAmount = useMemo(() => {
		return MathUtils.SubtractNumbers(MathUtils.MultiplyNumbers(`${players}`, costAmount), MathUtils.AddManyNumbers([prizes1, prizes2Normalized, prizes3Normalized]))
	}, [players, costAmount, prizes1, prizes2Normalized, prizes3Normalized])

	const getPrizes = (data: CreateSNGFormData) : string[] => {
		const prizes = [data.prizes1]
		if (data.prizes2 && MathUtils.IsBiggerThanZero(data.prizes2)){
			prizes.push(data.prizes2)
		}
		if (data.prizes3 && MathUtils.IsBiggerThanZero(data.prizes3)){
			prizes.push(data.prizes3)
		}
		return prizes
	}

	const onSubmit: SubmitHandler<CreateSNGFormData> = (data) => {
		setLoading(true)
		api
			.adminRoomsCreate({
                type: data.type as PokerGameType,
                format: PokerGameFormatType.Sng,
				roomName: data.roomName,
				requiredLevel: data.requiredLevel,
				maxPlayers: data.players,
				smallBlindAmount: data.startingSmallBlindAmount,
				bigBlindAmount: data.startingBigBlindAmount,
				ante: data.anteAmount,
                allowedToRebuy: false,
				minimumBuyIn: data.costAmount,
				maximumBuyIn: data.costAmount,
                isPotLimit: PokerGameType.Omaha === data.type || data.isPotLimit,
				timeToAct: data.timeToAct,
				timeToMuckReveal: data.timeToMuckReveal,
				tipDealerMultiplierAmount: data.tipDealerMultiplierAmount,
				rakePercents: [],
                tableBackgroundImage: data.tableBackgroundImage,
                tableMainImage: data.tableMainImage,
                sngConfig: {
					restartAfterFinished: data.restartAfterFinished,
					minimumPlayers: data.players,
                    prizes: getPrizes(data),
                    startingChips: data.startingChipsAmount,
                    startingSmallBlindAmount: data.startingSmallBlindAmount,
                    startingBigBlindAmount: data.startingBigBlindAmount,
                    maximumSmallBlindAmount: data.maximumSmallBlindAmount,
                    gamesToDoubleBlinds: data.gamesToDoubleBlinds
                }
			})
			.then(() => {
				showAlert({ type: 'success', message: 'SNG Created!' })
				navigate('/sngs')
			})
			.catch((err) => {
				showAlert({ type: 'error', message: getBOOError(err).message })
				setLoading(false)
			})
	}

	return (
		<FormProvider {...formMethods}>
			<form id='createsng-form' style={formStyle.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
				<h2 className='section-start'>General</h2>
				<Select
					labelId='type-select-label'
                    title='Game type'
                    {...formMethods.register('type', { required: 'game type is required!' })}
                    error={Boolean(formMethods.formState.errors.type)}
					defaultValue='HOLDEM'
				>
					<MenuItem value='HOLDEM'>Texas Hold&apos;em</MenuItem>
					<MenuItem value='OMAHA'>Omaha</MenuItem>
				</Select>
				<BOOTextInput
					id='name-field'
					style={formStyle.textField}
					formField='roomName'
					formValidation={{ required: true }}
					label='SNG name'
					placeholder='SNG name'
					variant='standard'
				/>
				<FormControlLabel style={formStyle.label} control={<Checkbox />} label='Restarts After Finished (Perpetual)' onChange={(_, checked) => formMethods.setValue('restartAfterFinished', checked)}/>
				<BOOTextInput
					id='level-field'
					style={formStyle.textField}
					formField='requiredLevel'
					formValidation={{
						min: 0,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Required level'
					placeholder='Required level'
					variant='standard'
				/>
				<BOOTextInput
					id='players-field'
					style={formStyle.textField}
					formField='players'
					formValidation={{
						min: 3,
						max: 9,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Number of player'
					placeholder='Number of players'
					variant='standard'
				/>
				<BOOTextInput
					id='timetoact-field'
					style={formStyle.textField}
					formField='timeToAct'
					formValidation={{
						min: 10,
						max: 60,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Time to act'
					placeholder='Time to act'
					InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}
					variant='standard'
				/>
				<BOOTextInput
					id='timetomuck-field'
					style={formStyle.textField}
					formField='timeToMuckReveal'
					formValidation={{
						min: 0,
                        max: 15,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Time to muck'
					placeholder='Time to muck'
					InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}
					variant='standard'
				/>
				<h2 className='section-start'>Cost & Prizes</h2>
				<p className='section-start'>Current Rake: ${rakeAmount} = {players}*${costAmount} - ${prizes1} - ${prizes2Normalized} - ${prizes3Normalized}</p>
				<BOOTextInput
					id='cost-field'
					style={formStyle.textField}
					formField='costAmount'
					formValidation={{
						min: 0,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Cost'
					placeholder='Cost'
                    // inputProps={{ step: 1 }}
					InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
					variant='standard'
				/>
				<BOOTextInput
					id='prize1-field'
					style={formStyle.textField}
					formField='prizes1'
					formValidation={{
						min: 1,
						max: 10000,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='1st Prize'
					placeholder='1st prize'
                    // inputProps={{ step: 1 }}
					InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
					variant='standard'
				/>
				<BOOTextInput
					id='prize2-field'
					style={formStyle.textField}
					formField='prizes2'
					formValidation={{
						min: 0,
						max: 10000,
						valueAsNumber: true,
						required: false,
					}}
					type='number'
					label='2nd Prize'
					placeholder='2nd prize'
                    // inputProps={{ step: 1 }}
					InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
					variant='standard'
				/>
				<BOOTextInput
					id='prize3-field'
					style={formStyle.textField}
					formField='prizes3'
					formValidation={{
						min: 0,
						max: 10000,
						valueAsNumber: true,
						required: false,
					}}
					type='number'
					label='3rd Prize'
					placeholder='3rd prize'
                    // inputProps={{ step: 1 }}
					InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
					variant='standard'
				/>
				<h2 className='section-start'>Blinds</h2>
				<BOOTextInput
					id='startingchipsamount-field'
					style={formStyle.textField}
					formField='startingChipsAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Starting Chips Amount'
					placeholder='Starting chips amount'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='startingsmallblindamount-field'
					style={formStyle.textField}
					formField='startingSmallBlindAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Starting Small Blind'
					placeholder='Starting small blind'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='startingbigblindamount-field'
					style={formStyle.textField}
					formField='startingBigBlindAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Starting Big Blind'
					placeholder='Starting big blind'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='maximumsmallblindamount-field'
					style={formStyle.textField}
					formField='maximumSmallBlindAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Maximum Small Blind'
					placeholder='Maximum small blind'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='gamestodoubleblinds-field'
					style={formStyle.textField}
					formField='gamesToDoubleBlinds'
					formValidation={{
						min: 3,
						max: 30,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Games To Double Blinds'
					placeholder='Games to double blinds'
					variant='standard'
				/>
				<BOOTextInput
					id='ante-field'
					style={formStyle.textField}
					formField='anteAmount'
					formValidation={{
						min: 0.0,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Ante'
					placeholder='Ante'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
                {typeWatch !== PokerGameType.Omaha && <FormControlLabel style={formStyle.label} control={<Checkbox />} label='Pot Limit' onChange={(_, checked) => formMethods.setValue('isPotLimit', checked)}/>}
				<BOOTextInput
					id='tablebackgroundimage-field'
					style={formStyle.textField}
					formField='tableBackgroundImage'
					formValidation={{ required: true }}
					label='Table Background Image'
					placeholder='Image name'
					variant='standard'
				/>
                <BOOTextInput
                    id='tablemainimage-field'
                    style={formStyle.textField}
                    formField='tableMainImage'
                    formValidation={{ required: true }}
                    label='Table Main Image'
                    placeholder='Image name'
                    variant='standard'
                />
				<BOOButton type='submit' style={formStyle.submitButton} form='createsng-form' variant='contained' loading={loading}>
					Create
				</BOOButton>
			</form>
		</FormProvider>
	)
}

const page: React.FC = () => {
	return (
		<>
			<SectionHeader title='Create Sit & Go' subtitle='Create a Sit & Go room' />
			<Form />
		</>
	)
}

export default page
