import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Alert } from 'boorio-portal-commons/components'

export type Event = Alert

export interface Events {
	events: Event[]
}

const initialState: Events = {
	events: [],
}

export const eventsSlice = createSlice({
	name: 'Events',
	initialState,
	reducers: {
		pushEvent: (state, action: PayloadAction<Event>) => {
			if (state.events.find((it) => it.message === action.payload.message) == null) {
				state.events.push(action.payload)
			}
		},
		removeEvent: (state, action: PayloadAction<Event>) => {
			state.events = state.events.filter((it) => it.message !== action.payload.message)
		},
	},
})

export const { pushEvent, removeEvent } = eventsSlice.actions

export default eventsSlice.reducer
