import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ModalId = string

export interface ModalState {
	open: ModalId[]
}

const initialState: ModalState = {
	open: [],
}

export const modalsSlice = createSlice({
	name: 'Modals',
	initialState,
	reducers: {
		open: (state, action: PayloadAction<ModalId>) => {
			if (!state.open.includes(action.payload)) {
				state.open.push(action.payload)
			}
		},
		close: (state, action: PayloadAction<ModalId>) => {
			state.open = state.open.filter((it) => it !== action.payload)
		},
	},
})

export const { open, close } = modalsSlice.actions

export default modalsSlice.reducer
