import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

// TODO: make a standard header
export interface CryptoDepositsHeaderProps {
	clickRefresh: () => void
	isRefreshing?: boolean
}

export const CryptoDepositsHeader: React.FC<CryptoDepositsHeaderProps> = ({ clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader
            title='Deposits'
        >
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}