import { CircularProgress } from '@mui/material'
import React from 'react'
import { R } from '../R/R'
import '../index.scss'

export default () => {
	return (
		<div id='loading-center-box'>
			<img src={R.images.iconBoorio} alt='boorio logo' />
			<CircularProgress id='loading-spinner' />
		</div>
	)
}
