import React, { RefObject, useState } from 'react'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { BOOButton } from 'boorio-portal-commons/components'
import { Fade } from '@mui/material'
import { WithdrawalRequestModel } from 'boorio-portal-commons/api'
import { UserTooltip } from '../../views/Popups/UserTooltip'
import { WithdrawalStatus } from './WithdrawalsPage'

export interface WithdrawalsListItemProps {
	withdrawal: WithdrawalRequestModel
	status: WithdrawalStatus
	clickApprove?: (request: WithdrawalRequestModel) => void
	clickDeny?: (request: WithdrawalRequestModel) => void
}

export const ApprovalStatusIndicator: React.FC<{ approved: boolean }> = ({ approved }) => {
	const title = approved ? 'Approved' : 'Denied'
	const icon = approved ? <CheckCircle color='success' style={{ marginLeft: '20px'}}/> : <Cancel color='error' style={{ marginLeft: '20px'}}/>
	return (
        <div className='boo-list-info'>
            <div>
			    {icon}<p className='normal'>{title}</p>
            </div>
		</div>
	)
}

interface PendingWithdrawalActionsProps {
	focused: boolean
	handleApprove: () => void
	handleDeny: () => void
}

export const PendingWithdrawalActions: React.FC<PendingWithdrawalActionsProps> = ({ focused, handleApprove, handleDeny }) => {
	return (
		<Fade in={focused}>
			<div className='boo-list-options'>
				<BOOButton onClick={handleApprove} color='success'>
                    <CheckCircle />
					&nbsp;Approve
				</BOOButton>
				<BOOButton onClick={handleDeny} color='error'>
                    <Cancel />
					&nbsp;Deny
				</BOOButton>
			</div>
		</Fade>
	)
}

export const WithdrawalsListItem: React.FC<WithdrawalsListItemProps> = ({ withdrawal, status, clickApprove, clickDeny }) =>
{
	const [focused, setFocused] = useState(false)

	const onMouseEnter = () => {
		setFocused(true)
	}
	const onMouseLeave = () => {
		setFocused(false)
	}
    const handleApprove = () => {
        if (clickApprove){
            clickApprove(withdrawal)
        }
    }
    const handleDeny = () => {
        if (clickDeny){
            clickDeny(withdrawal)
        }
    }

	return (
		<div className='boo-list-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<div className='boo-list-info'>
				<p className='index' style={{ minWidth: '200px', maxWidth: '200px', textAlign: 'left' }}>{withdrawal.id}</p>
                <UserTooltip userId={withdrawal.userId}>
				    <p className='wide'>{withdrawal.userId}</p>
                </UserTooltip>
				<p className='wide'>{withdrawal.toWallet}</p>
				<p className='normal'>${withdrawal.amount}</p>
                <div className='spacer' />
            </div>
            {status === 'resolved' && <ApprovalStatusIndicator approved={withdrawal.approved ?? false} />}
            {status === 'pending' && <PendingWithdrawalActions focused={focused} handleApprove={handleApprove} handleDeny={handleDeny} /> }
        </div>
	)
}
