import React, { useState } from 'react'
import { User, UserCognitoInfoModel, UserWalletModel } from 'boorio-portal-commons/api'
import { Info } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import { UserRefTooltip } from 'views/Popups/UserRefTooltip'

export interface UsersListItemProps
{
	user: User,
	wallet?: UserWalletModel,
	cognitoInfo?: UserCognitoInfoModel,
	onClick: (user: User) => void
}

export const UsersListItem: React.FC<UsersListItemProps> = ({ user, wallet, cognitoInfo, onClick }) => {
	const [focused, setFocused] = useState(false)

	return (
		<div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
			<div className='boo-list-info'>
                <img id='user-avatar' src={user.info.avatar} alt='avatar' />
                <p className='index' style={{ marginLeft: '50px', textAlign: 'left' }}>{user.info.name}</p>
                <p className='wide'>{user.id}</p>
                <p className='wide'>{cognitoInfo?.email ?? '-'}</p>
                <p className='normal'>{wallet?.address ?? '-'}</p>
                <p className='short'>{user.statistics.level}</p>
                <p className='short'>{user.statistics.global.handsPlayed}</p>
                <p className='short'>${user.statistics.global.rakePaidAmount}</p>
                <p className='short'>${user.statistics.global.winningsAmount}</p>
                <UserRefTooltip referralCode={user.registrationReferralCode ?? '-'}>
                	<p className='short'>{user.registrationReferralCode ?? '-'}</p>
                </UserRefTooltip>
                <p className='normal'>{cognitoInfo?.creationDate ?? '-'}</p>
			</div>
			<Fade in={focused}>
				<div className='boo-list-options'>
					<BOOButton onClick={() => onClick(user)}>
						<Info />
						&nbsp;Info
					</BOOButton>
				</div>
			</Fade>
		</div>
	)
}
