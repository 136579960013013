import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from 'utils/api/useApi'
import { AdminApi, UserApi } from 'boorio-portal-commons/api'
import { useNavigateWrapper } from 'utils/Navigation'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { getBOOError } from 'boorio-portal-commons'
import { useRefreshUsersAfterRemoval, useUser, useUserCognitoInfo, useUserWallet } from '../../../stores/hooks/usersHooks'
import { ChangePasswordFormData } from '../ChangePassword/ChangePasswordForm'
import { useModal } from '../../../stores/hooks/modalHooks'
import { ChangeNameFormData } from './ChangeName/ChangeNameForm'
import '../../../index.scss'
import { UserPageView } from './UserPageView'
import { ManualCreditFormData } from '../ManualCredit/ManualCreditForm'

export default () => {
	const navigate = useNavigateWrapper()
	const { showAlert, showError, catchAlert } = useReduxAlerts()
	const { userId } = useParams<{ userId: string }>()
	const { refreshUsersAfterRemoval } = useRefreshUsersAfterRemoval()
	const { user, refreshUser, updateUser } = useUser(userId!)
	const { userWallet, refreshUserWallet } = useUserWallet(userId!)
	const { userCognitoInfo, refreshUserCognitoInfo } = useUserCognitoInfo(userId!)
	const [isRefreshing, setRefreshing] = useState(false)
	const api = useApi(AdminApi)
	const userApi = useApi(UserApi)

    const idChangeName = 'changeName'
	const [, openChangeNameModal] = useModal(idChangeName)
    const [isChangeNameLoading, setChangeNameLoading] = useState(false)

    const idChangePassword = 'changePassword'
	const [, openChangePasswordModal] = useModal(idChangePassword)
    const [isChangePasswordLoading, setChangePasswordLoading] = useState(false)

    const idManualCredit = 'manualCredit'
	const [, openManualCreditModal] = useModal(idManualCredit)
    const [isManualCreditLoading, setManualCreditLoading] = useState(false)

    const idChangeReferralCode = 'changeReferralCode'
	const [, openChangeReferralCodeModal] = useModal(idChangeReferralCode)
	const [isChangeReferralCodeLoading, setChangeReferallCodeLoading] = useState(false)

    const idConfirmDelete = 'confirmDelete'
	const [, openConfirmDeleteModal] = useModal(idConfirmDelete)
	const [isConfirmDeleteLoading, setConfirmDeleteLoading] = useState(false)
   
    // TODO: this is annoying that it's here - has nothing to do with UI - would be nice to take out UI away from this logic
	const clickRefresh = () => {
		setRefreshing(true)

		refreshUser()
        .then(refreshUserWallet)
        .then(refreshUserCognitoInfo)
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

    const clickLock = async () => {
		setRefreshing(true)

        await api.adminUsersLock(user.id)
        .then(refreshUserCognitoInfo)
        .then(() => {
            // TODO: make a util showSuccess(title: string)
            showAlert({ type: 'success', message: 'User locked!' })
        })
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
    }
    const clickUnlock = async () => {
		setRefreshing(true)

        await api.adminUsersUnlock(user.id)
        .then(refreshUserCognitoInfo)
        .then(() => {
            // TODO: make a util showSuccess(title: string)
            showAlert({ type: 'success', message: 'User unlocked!' })
        })
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
    }
	const onConfirmDelete = async () =>
	{
		setRefreshing(true)

        await api.adminUsersRemove(user.id)
        .then(refreshUsersAfterRemoval)
        .then(() => {
            showAlert({ type: 'success', message: 'User removed!' })
			navigate('/users')
        })
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
    }
	const onConfirmChangeName = ({ newName }: ChangeNameFormData) =>
    {
		setChangeNameLoading(true)
		api
			.adminUsersChangeName(user.id, { value: `${newName}` })
			.then(async () => {
				// eslint-disable-next-line no-promise-executor-return
				await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
			})
			.then(() => {
				openChangeNameModal(false)
				showAlert({ type: 'success', message: 'Name changed successfully' })
			})
			.catch((res: any) => {
				const e = getBOOError(res)
				showAlert({ type: 'error', message: e.message })
				openChangeNameModal(false)
			})
			.finally(() => {
				openChangeNameModal(false)
			})
	}
	const onConfirmChangePassword = ({ newPassword }: ChangePasswordFormData) =>
    {
		setChangePasswordLoading(true)
		api
			.adminUsersResetPassword(user.id, { value: `${newPassword}` })
			.then(async () => {
				// eslint-disable-next-line no-promise-executor-return
				await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
			})
			.then(() => {
				openChangePasswordModal(false)
				showAlert({ type: 'success', message: 'Password changed successfully' })
			})
			.catch((res: any) => {
				const e = getBOOError(res)
				showAlert({ type: 'error', message: e.message })
				openChangePasswordModal(false)
			})
			.finally(() => {
				openChangePasswordModal(false)
			})
	}
	const onConfirmManualCredit = ({ amount }: ManualCreditFormData) =>
    {
		setManualCreditLoading(true)
		userApi
			.usersWalletManualCreditTransfer(user.id, { value: `${amount}` })
			.then(async () => {
				// eslint-disable-next-line no-promise-executor-return
				await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
			})
			.then(() => {
				openManualCreditModal(false)
				showAlert({ type: 'success', message: 'Account credited successfully' })
			})
			.catch((res: any) => {
				const e = getBOOError(res)
				showAlert({ type: 'error', message: e.message })
				openManualCreditModal(false)
			})
			.finally(() => {
				openManualCreditModal(false)
			})
	}

	return <UserPageView 
        user={user}
        userWallet={userWallet}
        userCognitoInfo={userCognitoInfo}

        clickRefresh={clickRefresh}
        isRefreshing={isRefreshing}
        clickLock={clickLock}
        clickUnlock={clickUnlock}

        changeNameProps={{
            user,
            id: idChangeName,
            clickSubmit: onConfirmChangeName,
            clickOpen: openChangeNameModal,
            loading: isChangeNameLoading
        }}
        changePasswordProps={{
            id: idChangePassword,
            clickSubmit: onConfirmChangePassword,
            clickOpen: openChangePasswordModal,
            loading: isChangePasswordLoading
        }}
        manualCreditProps={{
            id: idManualCredit,
            clickSubmit: onConfirmManualCredit,
            clickOpen: openManualCreditModal,
            loading: isManualCreditLoading
        }}
        confirmDeleteProps={{
            id: idConfirmDelete,
            clickSubmit: onConfirmDelete,
            clickOpen: openConfirmDeleteModal,
            loading: isConfirmDeleteLoading
        }}
    />
}
