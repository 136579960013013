import React from 'react'
import _ from 'lodash'
import { NavigateOptions, Route, useLocation, useNavigate } from 'react-router-dom'
import Login from 'pages/Login'
import Oops from 'pages/Oops'
import RoomPage from 'pages/Rooms/Detail/RoomPage'
import RoomsPage from 'pages/Rooms/RoomsPage'
import CreateRoom from 'pages/Rooms/Create/CreateRoom'
import SNGsPage from 'pages/SNGs/SNGsPage'
import SNGPage from 'pages/SNGs/Detail/SNGPage'
import CreateSNGPage from 'pages/SNGs/Create/CreateSNGPage'
import LevelRequirements from 'pages/Levels/LevelRequirements'
import Unauthorized from 'pages/Unauthorized'
import Profile from 'pages/Profile/Profile'
import WithdrawalsPage from 'pages/Withdrawals/WithdrawalsPage'
import { useIsLoggedIn } from 'stores/hooks/useActiveUser'
import Loading from 'pages/Loading'
import UsersPage from 'pages/Users/UsersPage'
import UserPage from 'pages/Users/Detail/UserPage'
import CryptoPage from 'pages/Crypto/CryptoPage'
import CryptoConversionsPage from 'pages/Crypto/Conversions/CryptoConversionsPage'
import CryptoDepositsPage from 'pages/Crypto/Deposits/CryptoDepositsPage'
import CryptoCreditsPage from 'pages/Crypto/Credits/CryptoCreditsPage'
import CryptoRakesPage from 'pages/Crypto/Rakes/CryptoRakesPage'
import OtherPage from 'pages/Other/OtherPage'
import BotsPage from 'pages/Users/Bots/BotsPage'

interface Route {
	(): JSX.Element
}

export type Auth = boolean

const navigation: any = {
	$: () => <Login />,
	loading: () => <Loading />,
	profile: {
		$auth: true,
		$: () => <Profile />,
	},
	levels: {
		$auth: true,
		$: () => <LevelRequirements />,
	},
	rooms: {
		$auth: true,
		$: () => <RoomsPage />,
		':id': () => <RoomPage />,
		create: {
			$auth: true,
			$: () => <CreateRoom />,
		}
	},
    sngs: {
        $auth: true,
        $: () => <SNGsPage />,
		':id': () => <SNGPage />,
		create: {
			$auth: true,
			$: () => <CreateSNGPage />,
		}
    },
	withdrawals: {
		$auth: true,
		$: () => <WithdrawalsPage />,
	},
	users: {
		$auth: true,
		$: () => <UsersPage />,
		':userId': () => <UserPage />,
	},
	bots: {
		$auth: true,
		$: () => <BotsPage />,
		// ':userId': () => <UserPage />,
	},
    crypto: {
		$auth: true,
        $: () => <CryptoPage />,
		rakes: {
			$auth: true,
			$: () => <CryptoRakesPage />,
		},
		deposits: {
			$auth: true,
			$: () => <CryptoDepositsPage />,
		},
		conversions: {
			$auth: true,
			$: () => <CryptoConversionsPage />,
		},
		credits: {
			$auth: true,
			$: () => <CryptoCreditsPage />,
		}
    },
	other: {
		$auth: true,
		$: () => <OtherPage />,
	},
	unauthorized: {
		$auth: true,
		$: () => <Unauthorized />,
	},
	'*': () => <Oops />,
}

const requiresAuth = (url: string, navigation: any, lastAuth: undefined): Auth => {
	let newLastAuth = lastAuth
	const DEFAULT_AUTH = false
	if (url.length === 0) return navigation.$auth ?? lastAuth ?? DEFAULT_AUTH

	const stack = url
		.split('/')
		.filter((it) => it.length > 0)
		.reverse()

	const top = stack.pop()

	if (navigation.$auth) {
		newLastAuth = navigation.$auth
	}
	if (typeof navigation[top!] === 'function') {
		return false
	}
	if (typeof navigation[top!] === 'object') {
		return requiresAuth(stack.reverse().join('/'), navigation[top!], newLastAuth)
	}

	const wildcard = _(Object.keys(navigation).filter((it) => it.startsWith(':'))).first()
	const hasWildCard = typeof wildcard !== 'undefined'

	if (hasWildCard) {
		return requiresAuth(stack.reverse().join('/'), navigation[wildcard!], newLastAuth)
	}

	return newLastAuth ?? DEFAULT_AUTH
}

const generateRoutes = (root: string, navigation: any): JSX.Element[] => {
	const pages: JSX.Element[] = []
	if (typeof navigation === 'function') {
		return [<Route key={root} path={root} element={navigation()} />]
	}
	if (typeof navigation.$ === 'function') {
		pages.push(<Route key={root} path={root} element={navigation.$()} />)
	}
	Object.keys(navigation).forEach((child) => {
		if (child !== '$' && child !== '$auth') {
			const result = generateRoutes(`${root}/${child}`, navigation[child])
			pages.push(...result)
		}
	})
	return pages
}

export const BOORoutes: () => JSX.Element[] = () => generateRoutes('/', navigation)

export const useRequiresAuth = (): Auth => {
	const location = useLocation()
	return requiresAuth(location.pathname, navigation, undefined)
}

export const useMatchesAuth = () => {
	const isLoggedIn = useIsLoggedIn()
	const auth = useRequiresAuth()
	return (auth && isLoggedIn) || (!auth && !isLoggedIn)
}

export const useNavigateWrapper = () => {
	const navigate = useNavigate()
	const authorized = useMatchesAuth()
	const loggedIn = useIsLoggedIn()

	const navigateFunc = (path: string, options?: NavigateOptions) => {
		if (authorized) {
            console.log('authorized navigate to: ', path)
			navigate(path, options)
		} else {
			if (loggedIn) {
				navigate('/unauthorized')
			} else {
                console.log('NOT LOGGED IN')
				navigate('/')
			}
		}
	}
	return navigateFunc
}
