import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { NavBar } from 'boorio-portal-commons/components'
import { when, whenVoid } from 'boorio-portal-commons'
import { R } from 'R/R'

type Routes<T extends string> = {
	[value in T]: RegExp
}

const MENU_ITEMS = [] as const
const NAV_BUTTONS = ['Rakes', 'Deposits', 'Conversions', 'Credits'] as const

type MenuItemsType = typeof MENU_ITEMS[number]
type NavButtonsType = typeof NAV_BUTTONS[number]

const NAV_ROUTES: Routes<NavButtonsType> = {
	Rakes: /\/rakes/,
	Deposits: /\/deposits/,
	Conversions: /\/conversions/,
	Credits: /\/credits/
}

export default () => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const active = NAV_BUTTONS.find((it) => NAV_ROUTES[it].test(location.pathname))

	const handleMenuItemClick = (menuItem: MenuItemsType) => {
		whenVoid(menuItem, {
		})
	}
	const handleButtonClick = (button: NavButtonsType) => {
		const path: string = when(button, {
			Rakes: () => '/crypto/rakes',
			Deposits: () => '/crypto/deposits',
			Conversions: () => '/crypto/conversions',
			Credits: () => '/crypto/credits'
		})
		navigate(path)
	}

	return NavBar<NavButtonsType, MenuItemsType>({
		appIcon: R.images.imageWallet,
		buttons: NAV_BUTTONS,
		active,
		onClick: handleButtonClick,
		menu: {
			items: MENU_ITEMS,
			onClick: handleMenuItemClick,
		},
        extras: []
	})
}
