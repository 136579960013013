import React, { useMemo } from 'react'
import { AdminMessageModel, LocalisationsModel, PokerGameModel } from 'boorio-portal-commons/api'
import { SubSectionHeader } from 'boorio-portal-commons/components/section/SubsectionHeader'
import { BOOButton } from 'boorio-portal-commons/components/controls/buttons/BOOButton'
import { Add, Edit, Start, Stop } from '@mui/icons-material'
import { JsonView } from 'boorio-portal-commons/components'
import { AddAdminMessageView, AddAdminMessageViewProps } from './AddAdminMessage/AddAdminMessageView'
import { InvalidateAdminMessageView, InvalidateAdminMessageViewProps } from './InvalidateAdminMessage/InvalidateAdminMessageView'
import { OtherPageHeader } from './OtherPageHeader'
import { UpdateLocalisationView, UpdateLocalisationViewProps } from './UpdateLocalisation/UpdateLocalisationView'

export interface OtherPageViewProps {
	isBEUpdatePending: boolean,
	adminMessage: AdminMessageModel,
	localisations: LocalisationsModel,
	clickRefresh: () => void,
	isRefreshing: boolean,
	clickRetryPayments: () => void,
	isRetryPayments: boolean,
	
	addAdminMessageProps: AddAdminMessageViewProps,
	invalidateAdminMessageProps: InvalidateAdminMessageViewProps,
	updateLocalisationProps: UpdateLocalisationViewProps
}

export const OtherPageView : React.FC<OtherPageViewProps> = ({ isBEUpdatePending, adminMessage, localisations, clickRefresh, isRefreshing, clickRetryPayments, isRetryPayments, addAdminMessageProps, invalidateAdminMessageProps, updateLocalisationProps }) =>
{
    const actions = useMemo(() => {
        return (
            <>
				<BOOButton variant='contained' onClick={() => addAdminMessageProps.clickOpen(true)}>
					<Add />
					&nbsp;Add AdminMessage
				</BOOButton>
                <BOOButton variant='contained' onClick={() => invalidateAdminMessageProps.clickOpen(true)} color='error' style={{ marginLeft: '10px' }}>
					<Stop />
					&nbsp;Invalidate AdminMessage
				</BOOButton>
				<BOOButton variant='contained' onClick={() => updateLocalisationProps.clickOpen(true)} style={{ marginLeft: '10px' }}>
					<Edit />
					&nbsp;Edit Localisation
				</BOOButton>
                <BOOButton variant='contained' loading={isRetryPayments} onClick={clickRetryPayments} color='success' style={{ marginLeft: '10px' }}>
					<Start />
					&nbsp;Retry Payments
				</BOOButton>
            </>
        )
    }, [])
	return (
		<>
			<OtherPageHeader isBEUpdatePending={isBEUpdatePending} clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			<SubSectionHeader title='Actions' />
			<div id='actions' className='section'>{actions}</div>

			<SubSectionHeader title='AdminMessage'/> 
			<JsonView name='AdminMessage' object={adminMessage} />

			<SubSectionHeader title='Localisations'/> 
			<JsonView name='Localisations' object={localisations} />

			<AddAdminMessageView {...addAdminMessageProps} />
			<InvalidateAdminMessageView {...invalidateAdminMessageProps} />
			<UpdateLocalisationView {...updateLocalisationProps} />
		</>
	)
}
