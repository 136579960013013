import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import AddBotForm, { AddBotFormData } from './AddBotForm'

export type AddBotViewProps = {
	clickSubmit: SubmitHandler<AddBotFormData>
} & CommonModalProps

export const AddBotView : React.FC<AddBotViewProps> = ({ id, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<AddBotForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}
