import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import sessionStorage from 'redux-persist/lib/storage/session'
import admin from './features/Admin'
import activeUser from './features/ActiveUser'
import crypto from './features/Crypto'
import rooms from './features/RoomsStore'
import modals from './features/Modals'
import levelRequirements from './features/LevelRequirements'
import websocketEvents from './features/WebsocketEvents'
import users from './features/UsersStore'
import withdrawals from './features/Withdrawals'
import popups from './features/Popups'
import events from './features/EventsReducer'
import stats from './features/StatsStore'
import gameRecords from './features/GameRecordsStore'

const reducer = combineReducers({
	admin,
	activeUser,
    crypto,
	rooms,
	modals,
	levelRequirements,
	websocketEvents,
	users,
    withdrawals,
	popups,
	events,
	stats,
	gameRecords
})

export const store = configureStore({
	reducer: persistReducer({ key: 'root', storage: sessionStorage }, reducer),
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
