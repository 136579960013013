import React from 'react'
import { Cancel, Money, Save } from '@mui/icons-material'
import { FormControl, InputAdornment } from '@mui/material'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

export interface ManualCreditFormData {
	amount: string
}

export type Props = {
	loading?: boolean
	onSubmit: SubmitHandler<ManualCreditFormData>
	onCancel: () => void
}

const Page: React.FC<Props> = (props: Props) => {
	const { loading, onSubmit, onCancel } = props

	const formMethods = useForm<ManualCreditFormData>({})

	return (
		<FormProvider {...formMethods}>
			<FormControl fullWidth id='manual-credit-form' component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
                <BOOTextInput
					id='amount-input'
					formField='amount'
					formValidation={{ required: true, valueAsNumber: true }}
					label='Amount'
					InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
				/>
                <div className='flex mt-5 justify-end'>
                    <BOOButton startIcon={<Money />} type='submit' variant='contained' style={{ marginRight: '5px' }} loading={loading}>
                        Credit
                    </BOOButton>
                    <BOOButton startIcon={<Cancel />} variant='outlined' disabled={loading} onClick={onCancel}>
                        Cancel
                    </BOOButton>
			    </div>
			</FormControl>
		</FormProvider>
	)
}

export default Page