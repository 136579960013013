import React from 'react'
import { BOOButton, RefreshButton, SectionHeader } from 'boorio-portal-commons/components'
import { AddCircle } from '@mui/icons-material'

export interface SNGsHeaderProps {
	clickAddSNG: () => void,
	clickRefresh: () => void,
	isRefreshing?: boolean,
    sngsCount: number
}

export const SNGsHeader: React.FC<SNGsHeaderProps> = ({ clickAddSNG, clickRefresh, isRefreshing, sngsCount }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader title='SNGs'>
            <BOOButton variant='contained' onClick={clickAddSNG}>
                <AddCircle />
                &nbsp;Create SNG
            </BOOButton>
            <RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
        </SectionHeader>
	)
}