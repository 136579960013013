import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserLevelRequirementModel } from 'boorio-portal-commons/api'

export interface LevelRequirements {
	levels: UserLevelRequirementModel[]
}

const initialState: LevelRequirements = {
	levels: [],
}

export const levelsSlice = createSlice({
	name: 'LevelRequirements',
	initialState,
	reducers: {
		refreshLevelRequirements: (state, action: PayloadAction<UserLevelRequirementModel[]>) => {
			state.levels = action.payload
		},
		updateLevelRequirement: (state, action: PayloadAction<UserLevelRequirementModel>) => {
			state.levels = state.levels.filter((it) => it.level !== action.payload.level)
			state.levels = [...state.levels, action.payload]
		},
	},
})

export const { refreshLevelRequirements, updateLevelRequirement } = levelsSlice.actions

export default levelsSlice.reducer
