import React, { useEffect, useMemo, useState } from 'react'
import { SubSectionHeader } from 'boorio-portal-commons/components'
import { ConversionTransactionModel } from 'boorio-portal-commons/api'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useConversionsUnknown, useRetryConversion } from '../../../stores/hooks/cryptoHooks'
import '../../../index.scss'
import { CryptoConversionListItem } from './CryptoConversionListItem'
import CryptoNavBar from '../CryptoNavBar'
import { CryptoConversionListHeader } from './CryptoConversionListHeader'
import { CryptoConversionsHeader } from './CryptoConversionsHeader'
import { getBOOError } from '../../../utils/error/BOOError'


export default () => {
	const [isRefreshing, setRefreshing] = useState(false)
	const { conversionsUnknown, refreshConversionsUnknown } = useConversionsUnknown()
    const retryConversion = useRetryConversion()
	const { showAlert, showError } = useReduxAlerts()

	const clickRefresh = () => {
		setRefreshing(true)

		refreshConversionsUnknown()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const clickRetry = (conversion: ConversionTransactionModel) => {
		setRefreshing(true)
		retryConversion(conversion)
		.then(() => {
            showAlert({ type: 'success', message: 'Conversion successfully re-processed' })
		})
        .catch((err) => {
            showError(getBOOError(err))
        })
        .finally(() => setRefreshing(false))
	}

	const unknownConversionsViews = useMemo(
		() =>
        conversionsUnknown.map((it) => (
				<CryptoConversionListItem conversion={it} clickRetry={clickRetry}  />
			)),
		[conversionsUnknown],
	)

	return (
		<>
			<CryptoNavBar/>
			<CryptoConversionsHeader clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			
			<SubSectionHeader title='Unknown Conversions' />
			<div className='boo-list'>
				<CryptoConversionListHeader />
				{unknownConversionsViews}
			</div>
		</>
	)
}
