import { CircularProgress, Tooltip } from '@mui/material'
import { getBOOError } from 'boorio-portal-commons'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useUser, useUserCognitoInfo, useUserWallet } from '../../stores/hooks/usersHooks'
import './Tooltip.scss'

export const UserTooltip = ({ userId, children } : { userId: string; children: ReactElement<any, any> }) => {
	const navigate = useNavigate()
	const { showError } = useReduxAlerts()
	const { user, refreshUser } = useUser(userId)
	const { userWallet, refreshUserWallet } = useUserWallet(userId)
	const { userCognitoInfo, refreshUserCognitoInfo } = useUserCognitoInfo(userId)
	const [isRefreshing, setRefreshing] = useState(false)
    
	const clickRefresh = () => {
		setRefreshing(true)

		refreshUser()
        .then(refreshUserWallet)
        .then(refreshUserCognitoInfo)
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const handleClick = useCallback(() => {
		navigate(`/users/${userId}`)
	}, [])

	const name = user?.info.name
	const avatar = user?.info.avatar
    const walletAddress = userWallet?.address
    const email = userCognitoInfo?.email

	const title = useMemo(() => {
		return (
			<>
				{isRefreshing && <CircularProgress />}
                <img id='user-avatar' src={avatar} alt='avatar' />
				<p>Name: {name}</p>
                <p>Wallet: {walletAddress}</p>
                <p>E-mail: {email}</p>
				<p>Id: {user?.id}</p>
			</>
		)
	}, [isRefreshing, name, avatar, walletAddress, email])
	return (
		<Tooltip className='tooltip' title={title} placement='top' arrow onClick={handleClick}>
			{children}
		</Tooltip>
	)
}
