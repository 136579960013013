import React, { useEffect, useMemo, useState } from 'react'
import { SubSectionHeader } from 'boorio-portal-commons/components'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { getBOOError } from 'boorio-portal-commons'
import '../../index.scss'
import { WithdrawalsListItem } from './WithdrawalsListItem'
import { WithdrawalsHeader } from './WithdrawalsHeader'
import { WithdrawalsListHeader } from './WithdrawalsListHeader'
import { useRefreshWithdrawals, useApproveWithdrawal, useDenyWithdrawal } from '../../stores/hooks/withdrawalsHooks'
import { useModal } from '../../stores/hooks/modalHooks'
import { BOOConfirmationModal } from '../../views/Modal/BOOConfirmationModal'

export type WithdrawalStatus = 'pending' | 'resolved'

export default () => {
	const { withdrawals, refreshWithdrawals } = useRefreshWithdrawals()
	const approveWithdrawal = useApproveWithdrawal()
	const denyWithdrawal = useDenyWithdrawal()
	const [, showApproveModal] = useModal('confirmApprove')
	const [, showDenyModal] = useModal('confirmDeny')
	const [approvingWithdrawal, setApprovingWithdrawal] = useState<string | undefined>()
	const [denyingWithdrawal, setDenyingWithdrawal] = useState<string | undefined>()
	const [isRefreshing, setRefreshing] = useState(false)
	const { showAlert, showError } = useReduxAlerts()

	const clickRefresh = () => {
		setRefreshing(true)
		refreshWithdrawals()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

    const clickApprove = (id: string) => {
		setApprovingWithdrawal(id)
		showApproveModal(true)
    }
	const onConfirmApprove = () => {
        const id = approvingWithdrawal!
		showApproveModal(false)
		setRefreshing(true)
		setApprovingWithdrawal(undefined)

        approveWithdrawal(id)
        .then(() => {
            showAlert({ type: 'success', message: 'Withdrawal request approved' })
        })
        .catch((err) => {
            showError(getBOOError(err))
        })
        .finally(() => setRefreshing(false))
    }

    const clickDeny = (id: string) => {
		setDenyingWithdrawal(id)
		showDenyModal(true)
    }
	const onConfirmDeny = () => {
        const id = denyingWithdrawal!

		showDenyModal(false)
		setRefreshing(true)
		setDenyingWithdrawal(undefined)

        denyWithdrawal(id)
        .then(() => {
            // TODO: make a util showSuccess(title: string)
            showAlert({ type: 'success', message: 'Withdrawal request Denied' })
        })
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
    }

    const pendingWithdrawals = withdrawals.filter((w) => !w.processed)
    const resolvedWithdrawals = withdrawals.filter((w) => w.processed)

	const pendingViews = useMemo(
		() =>
			pendingWithdrawals.map((it) => (
				<WithdrawalsListItem
					key={it.id}
					withdrawal={it}
					status='pending'
					clickApprove={() => clickApprove(it.id)}
					clickDeny={() => clickDeny(it.id)}
				/>
			)),
		[pendingWithdrawals],
	)
	const resolvedViews = useMemo(
		() =>
			resolvedWithdrawals.map((it) => (
				<WithdrawalsListItem
					key={it.id}
					withdrawal={it}
					status='resolved'
				/>
			)),
		[resolvedWithdrawals],
	)

	return (
		<>
            <WithdrawalsHeader clickRefresh={refreshWithdrawals} isRefreshing={isRefreshing} />
			<SubSectionHeader title='Pending' />
			<div className='boo-list'>
				<WithdrawalsListHeader status='pending' />
				{pendingViews}
			</div>
			<SubSectionHeader title='Resolved' />
			<div className='boo-list'>
				<WithdrawalsListHeader status='resolved' />
				{resolvedViews}
			</div>
            
            <BOOConfirmationModal
                id='confirmApprove'
                title='Approve withdrawal'
                description='Do you want to approve? You should`ve made the transfer to the user`s wallet and only then approve!'
                okTitle='APPROVE'
                cancelTitle='Cancel'
                onClickOk={onConfirmApprove}
            />
            <BOOConfirmationModal
                id='confirmDeny'
                title='Deny withdrawal'
                description='Do you want to deny? The funds will be available for the player to play in-game again.'
                okTitle='DENY'
                cancelTitle='Cancel'
                onClickOk={onConfirmDeny}
            />
		</>
	)
}
