import React, { useEffect, useState } from 'react'
import { User } from 'boorio-portal-commons/api'
import { useGetUserDatas, useRefreshUsers, useRefreshUserWallets, useRefreshUserCognitoInfos } from '../../stores/hooks/usersHooks'
import { useNavigateWrapper } from '../../utils/Navigation'
import { UsersListItem } from './UsersListItem'
import '../../index.scss'
import { UsersListHeader } from './UsersListHeader'
import { UsersHeader } from './UsersHeader'

export default () => {
	const navigate = useNavigateWrapper()
	const { userDatas } = useGetUserDatas()
	const { refreshUsers } = useRefreshUsers()
	const { refreshUserWallets } = useRefreshUserWallets()
	const { refreshUserCognitoInfos } = useRefreshUserCognitoInfos()
	const [isRefreshing, setRefreshing] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)

        refreshUsers()
		.then(() => refreshUserWallets())
		.then(() => refreshUserCognitoInfos())
        .finally(() => setRefreshing(false))
	}

	const clickBots = () => {
		navigate('/bots')
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const handleUserClick = (user: User) => {
		navigate(`/users/${user.id}`)
	}

    const usersList = Object.entries(userDatas)
	const items = usersList.map(([userId, userData]) => <UsersListItem key={userId} user={userData.user} wallet={userData.wallet} cognitoInfo={userData.cognitoInfo} onClick={handleUserClick} />)

	return (
		<>
			<UsersHeader clickBots={clickBots} clickRefresh={clickRefresh} isRefreshing={isRefreshing} usersCount={usersList.length} />
			<div className='boo-list'>
				<UsersListHeader />
				{items}
			</div>
		</>
	)
}
