import React, { useState } from 'react'
import { CryptoTransactionModel } from 'boorio-portal-commons/api'

export interface CryptoTransactionListItemProps {
	transaction: CryptoTransactionModel
}

export const CryptoTransactionListItem: React.FC<CryptoTransactionListItemProps> = ({ transaction }) =>
{
	const [focused, setFocused] = useState(false)

	const onMouseEnter = () => {
		setFocused(true)
	}
	const onMouseLeave = () => {
		setFocused(false)
	}

	return (
		<div className='boo-list-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<div className='boo-list-info'>
				<a href={`https://bscscan.com/tx/${transaction.hash}`}><p className='index'>{transaction.hash}</p></a>
				<a href={`https://bscscan.com/address/${transaction.from}`}><p className='wide'>{transaction.from}</p></a>
				{ transaction.tokenContractAddress ? <a href={`https://bscscan.com/token/${transaction.tokenContractAddress}`}><p className='normal'>{transaction.tokenSymbol}</p></a> : <p className='normal'>{transaction.tokenSymbol}</p> }
				<p className='normal'>{transaction.value}</p>
				<p className='normal'>{transaction.status}</p>
				<p className='normal'>{transaction.timeStamp}</p>
                <div className='spacer' />
            </div>
        </div>
	)
}
