import React from 'react'

export const CryptoCreditListHeader : React.FC<{}> = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='index'>ID</p>
                <p className='wide'>Hash</p>
                <p className='normal'>Deposit From Address</p>
                <p className='normal'>Token</p>
                <p className='normal'>Value</p>
                <p className='normal'>Status</p>
                <p className='normal'>Deposit Token</p>
                <p className='normal'>Deposit Value</p>
            </div>
		</div>
	)
}
