import React, { useEffect, useMemo, useState } from 'react'
import { SubSectionHeader } from 'boorio-portal-commons/components'
import { CreditTransactionModel } from 'boorio-portal-commons/api'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useCreditsUnknown, useAssignCredit } from '../../../stores/hooks/cryptoHooks'
import '../../../index.scss'
import CryptoNavBar from '../CryptoNavBar'
import { CryptoCreditListItem } from './CryptoCreditListItem'
import { CryptoCreditListHeader } from './CryptoCreditListHeader'
import { CryptoCreditsHeader } from './CryptoCreditsHeader'
import AssignCreditForm, { AssignCreditFormData } from './AssignCredit/AssignCreditForm'
import { getBOOError } from '../../../utils/error/BOOError'
import { useModal } from '../../../stores/hooks/modalHooks'
import { BOOModal } from '../../../views/Modal/BOOModal'


export default () => {
	const [isRefreshing, setRefreshing] = useState(false)
	const [selectedCredit, setSelectedCredit] = useState<CreditTransactionModel | undefined>()
	const { creditsUnknown, refreshCreditsUnknown } = useCreditsUnknown()
    const assignCredit = useAssignCredit()
	const { showAlert } = useReduxAlerts()
	const [, openAssignCreditModal] = useModal('assignCredit')
	const [assignCreditLoading, setAssignCreditLoading] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)

		refreshCreditsUnknown()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const onClickAssign = (credit: CreditTransactionModel) => {
		setSelectedCredit(credit)
		openAssignCreditModal(true)
	}
	const onSubmitAssignCredit = ({ userId } : AssignCreditFormData) => {
		setAssignCreditLoading(true)
		assignCredit(selectedCredit!, userId)
        .then(() => {
            openAssignCreditModal(false)
            showAlert({ type: 'success', message: 'Credit assigned successfuly' })
        })
        .catch((res: any) => {
            const booErr = getBOOError(res)
            showAlert({ type: 'error', message: booErr.message })
            openAssignCreditModal(false)
        })
        .finally(() => {
            setAssignCreditLoading(false)
        })
	}
	const onCancelAssignCredit = () => {
		openAssignCreditModal(false)
	}

	const unknownCreditsViews = useMemo(
		() =>
        creditsUnknown.map((it) => (
				<CryptoCreditListItem credit={it} clickAssignCredit={onClickAssign} />
			)),
		[creditsUnknown],
	)

	return (
		<>
			<CryptoNavBar/>
			<CryptoCreditsHeader clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			
			<SubSectionHeader title='Unknown Credits' />
			<div className='boo-list'>
				<CryptoCreditListHeader />
				{unknownCreditsViews}
			</div>

			<BOOModal id='assignCredit'>
				<AssignCreditForm loading={assignCreditLoading} onSubmit={onSubmitAssignCredit} onCancel={onCancelAssignCredit} />
			</BOOModal>
		</>
	)
}
