import React from 'react'
import { WithdrawalStatus } from './WithdrawalsPage'

export interface WithdrawalsListHeaderProps
{
	status: WithdrawalStatus
}

export const WithdrawalsListHeader : React.FC<WithdrawalsListHeaderProps> = ({ status }) => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='index' style={{ minWidth: '200px', maxWidth: '200px', textAlign: 'left' }}>ID</p>
                <p className='wide'>User ID</p>
                <p className='wide'>Wallet ID</p>
                <p className='normal'>Amount</p>
            </div>
            <div className='boo-list-info'>
			    {status === 'resolved' && <p className='normal'>Status</p>}
            </div>
		</div>
	)
}
