import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoginRequest, AdminApi } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { RootState } from '../AppStore'
import { setLoggingIn, login as saveLogin, setError } from '../features/ActiveUser'

export const useActiveUser = () => useSelector((state: RootState) => state.activeUser)
export const useIsLoggedIn = () => useSelector((state: RootState) => state.activeUser.user !== undefined)
export const useAdmin = () => useSelector((state: RootState) => state.activeUser.user?.user)

/// Logs in and saves data to redux
export const useLogin = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const activeUser = useActiveUser()

	const action = useCallback((auth: LoginRequest) =>
    {
        dispatch(setLoggingIn(true))
        
        api.authAdminLogin(auth)
        .then((res) => {
            console.log(res)
            dispatch(saveLogin(res.data))
        })
        .catch((err) => {
            console.log(err)
            dispatch(setError(err))
        })
    },
    [dispatch, api])

	return { login: action, activeUser }
}
