import React from 'react'

export const CryptoDepositListHeader : React.FC<{}> = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='index'>ID</p>
                <p className='wide'>Hash</p>
                <p className='normal'>Min Not Met</p>
                <p className='normal'>Failed Conversion Tx</p>
                <p className='normal'>Conversion Send Error</p>
                <p className='wide'>From</p>
                <p className='normal'>Token</p>
                <p className='normal'>Value</p>
                <p className='normal'>Status</p>
                <p className='normal'>Timestamp</p>
            </div>
		</div>
	)
}
