import React, { useEffect, useState } from 'react'
import { useModal } from 'stores/hooks/modalHooks'
import { useApi } from 'utils/api/useApi'
import { AdminApi } from 'boorio-portal-commons/api'
import { useRefreshAdminMessage, useRefreshLocalisations } from 'stores/hooks/adminHooks'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { OtherPageView } from './OtherPageView'
import { AddAdminMessageFormData } from './AddAdminMessage/AddAdminMessageForm'
import { UpdateLocalisationFormData } from './UpdateLocalisation/UpdateLocalisationForm'
import '../../index.scss'

export default () => {
	const { adminMessage, refreshAdminMessage, invalidateAdminMessage, addAdminMessage } = useRefreshAdminMessage()
	const { localisations, refreshLocalisations, updateLocalisation } = useRefreshLocalisations()
	const { catchAlert } = useReduxAlerts()
	const [isRefreshing, setRefreshing] = useState(false)
	const [isRetryPayments, setRetryPayments] = useState(false)
	const adminApi = useApi(AdminApi)

    const idAddAdminMessage = 'addAdminMessage'
	const [, openAddAdminMessageModal] = useModal(idAddAdminMessage)
	const [isAddAdminMessageLoading, setAddAdminMessageLoading] = useState(false)

    const idInvalidateAdminMessage = 'invalidateAdminMessage'
	const [, openInvalidateAdminMessageModal] = useModal(idInvalidateAdminMessage)
	const [isInvalidateAdminMessageLoading, setInvalidateAdminMessageLoading] = useState(false)

    const idUpdateLocalisation = 'updateLocalisation'
	const [, openUpdateLocalisationModal] = useModal(idUpdateLocalisation)
	const [isUpdateLocalisationLoading, setUpdateLocalisationLoading] = useState(false)
    
	const clickRefresh = () => {
		setRefreshing(true)

		refreshAdminMessage()
        .then(() => refreshLocalisations())
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

    const clickRetryPayments = () => {
		setRetryPayments(true)

        adminApi.adminsUtilsResetAllIgnoredTransfers()
        .finally(() => setRetryPayments(false))
    }

	const onConfirmAddAdminMessage = (data: AddAdminMessageFormData) => {
		setAddAdminMessageLoading(true)
        catchAlert(
            addAdminMessage(data.message),
            'AdminMessage added'
        )
        .finally(() => {
            setAddAdminMessageLoading(false)
            openAddAdminMessageModal(false)
        })
    }

	const onConfirmInvalidateAdminMessage = () => {
		setInvalidateAdminMessageLoading(true)
        catchAlert(
            invalidateAdminMessage(),
            'AdminMessage invalidated'
        )
        .finally(() => {
            setInvalidateAdminMessageLoading(false)
            openInvalidateAdminMessageModal(false)
        })
    }

	const onConfirmUpdateLocalisation = (data: UpdateLocalisationFormData) => {
		setUpdateLocalisationLoading(true)
        catchAlert(
            updateLocalisation(data.key, data.value),
            'Localisation updated'
        )
        .finally(() => {
            setUpdateLocalisationLoading(false)
            openUpdateLocalisationModal(false)
        })
    }

	return <OtherPageView 
        isBEUpdatePending={false}
        adminMessage={adminMessage}
        localisations={localisations}
        clickRefresh={clickRefresh}
        isRefreshing={isRefreshing}
        clickRetryPayments={clickRetryPayments}
        isRetryPayments={isRetryPayments}
        addAdminMessageProps={{
            id: idAddAdminMessage,
            clickSubmit: onConfirmAddAdminMessage,
            clickOpen: openAddAdminMessageModal,
            loading: isAddAdminMessageLoading
        }}
        invalidateAdminMessageProps={{
            id: idInvalidateAdminMessage,
            message: adminMessage,
            clickSubmit: onConfirmInvalidateAdminMessage,
            clickOpen: openInvalidateAdminMessageModal,
            loading: isInvalidateAdminMessageLoading
        }}
        updateLocalisationProps={{
            id: idUpdateLocalisation,
            localisations,
            clickSubmit: onConfirmUpdateLocalisation,
            clickOpen: openUpdateLocalisationModal,
            loading: isUpdateLocalisationLoading
        }}
    />
}
