import React, { useState } from 'react'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { Edit, VisibilityOff } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import MathUtils from 'boorio-portal-commons/utils/MathUtils'
import './RoomsListItem.scss'

export interface RoomsListItemProps {
	room: PokerGameModel
	clickRoom: (room: PokerGameModel) => void
}

export const RoomsListItem: React.FC<RoomsListItemProps> = ({ room, clickRoom }) => {
	const [focused, setFocused] = useState(false)
	
    const gamesForPot = room.statistics.gamesPlayed === 0 ? 1 : room.statistics.gamesPlayed

    return (
        <div>
            {room.status.hidden && <div className='room-hidden'><VisibilityOff /></div>}
            <div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
                <div className='boo-list-info'>
                    <p className='normal' style={{ marginLeft: '0px' }}>{room.config.type}</p>
                    <p className='index'>{room.config.roomName}</p>
                    <p className='normal'>${room.config.smallBlindAmount} | ${room.config.bigBlindAmount} | {room.config.isPotLimit ? 'PL' : 'NL'} | ${room.config.ante}</p>
                    <p className='short'>{MathUtils.MultiplyNumbers(room.config.rakePercents[0].percent, '100')}%</p>
                    <p className='normal'>${room.config.minimumBuyIn} | ${room.config.maximumBuyIn}</p>
                    <p className='short'>Lvl {room.config.requiredLevel}</p>
                    <p className='short'>{room.players.length}/{room.config.maxPlayers}</p>
                    <p className='normal'>{room.roundData.type} | {room.turnData.type}</p>
                    <p className='short'>{room.statistics.gamesPlayed}</p>
                    <p className='normal'>${(Number(room.statistics.totalPotAmount)/gamesForPot).toFixed(2)} | {(room.statistics.playersAtStart/gamesForPot).toFixed(2)}</p>
                </div>
                <Fade in={focused}>
                    <div className='boo-list-options'>
                        <BOOButton onClick={() => clickRoom(room)}>
                            <Edit />
                            &nbsp;Edit
                        </BOOButton>
                    </div>
                </Fade>
            </div>
        </div>
    )
}
