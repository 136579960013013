import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useActiveUser, useIsLoggedIn } from '../../stores/hooks/useActiveUser'
import { useMatchesAuth, useRequiresAuth } from '../Navigation'
import useAuthenticatedPage from '../useAuthenticatedPage'

export const useRedirectUnauthorized = () => {
	const matchesAuth = useMatchesAuth()
	const isLoggedIn = useIsLoggedIn()
	const navigate = useNavigate()

	useEffect(() => {
		if (!matchesAuth) {
			if (isLoggedIn) {
				navigate('/unauthorized')
			} else {
				navigate('/')
			}
		}
	}, [isLoggedIn, matchesAuth, navigate])
}
