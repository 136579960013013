import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export interface WebSocketEvents {
	events: string[]
}

const initialState: WebSocketEvents = {
	events: [],
}

export const wsEventsSlice = createSlice({
	name: 'WebSocketEvents',
	initialState,
	reducers: {
		clearEvents: (state) => {
			state.events = []
		},
		addEvent: (state, action: PayloadAction<string>) => {
			state.events.push(action.payload)
		},
	},
})

export const { clearEvents, addEvent } = wsEventsSlice.actions

export default wsEventsSlice.reducer
