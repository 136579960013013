import React, { useMemo } from 'react'
import { User, UserCognitoInfoModel, UserWalletModel } from 'boorio-portal-commons/api'
import { SubSectionHeader } from 'boorio-portal-commons/components/section/SubsectionHeader'
import { BOOButton } from 'boorio-portal-commons/components/controls/buttons/BOOButton'
import { Add, LockOpen, LockReset, Lock, VerifiedUser, ChangeCircle, ChangeCircleOutlined, Money } from '@mui/icons-material'
import { JsonView } from 'boorio-portal-commons/components'
import { UserHeader } from './UserHeader'
import { ChangePasswordView, ChangePasswordViewProps } from '../ChangePassword/ChangePasswordView'
import { ChangeNameView, ChangeNameViewProps } from './ChangeName/ChangeNameView'
import { ManualCreditView, ManualCreditViewProps } from '../ManualCredit/ManualCreditView'
import { ConfirmDeleteView, ConfirmDeleteViewProps } from '../ConfirmDelete/ConfirmDeleteView'

export interface UserPageViewProps {
	user: User,
	userWallet?: UserWalletModel,
	userCognitoInfo?: UserCognitoInfoModel,

	clickRefresh: () => void,
	isRefreshing: boolean,
	clickLock: () => void,
	clickUnlock: () => void,
	
    changeNameProps: ChangeNameViewProps,
	changePasswordProps: ChangePasswordViewProps,
	manualCreditProps: ManualCreditViewProps,
	confirmDeleteProps: ConfirmDeleteViewProps
}

export const UserPageView : React.FC<UserPageViewProps> = ({ user, userWallet, userCognitoInfo, clickRefresh, isRefreshing, clickLock, clickUnlock, changeNameProps, changePasswordProps, manualCreditProps, confirmDeleteProps }) =>
{
    const isLocked = userCognitoInfo?.enabled === false
	
    const actions = useMemo(() => {
        return (
            <>
                <BOOButton variant='contained' onClick={() => changeNameProps.clickOpen(true)} style={{ marginLeft: '10px'}} color='success'>
                    <ChangeCircleOutlined />
                    &nbsp;Change Name
                </BOOButton>
                <BOOButton variant='contained' onClick={() => changePasswordProps.clickOpen(true)} style={{ marginLeft: '10px'}}>
                    <LockReset />
                    &nbsp;Change Password
                </BOOButton>
                <BOOButton variant='contained' onClick={() => manualCreditProps.clickOpen(true)} style={{ marginLeft: '10px'}} color='success'>
                    <Money />
                    &nbsp;Manual Credit
                </BOOButton>
                {!isLocked && <BOOButton variant='contained' onClick={clickLock} style={{ marginLeft: '10px'}} color='error'>
                    <Lock />
                    &nbsp;Lock
                </BOOButton>}
                {isLocked && <BOOButton variant='contained' onClick={clickUnlock} style={{ marginLeft: '10px'}} color='success'>
                    <LockOpen />
                    &nbsp;Unlock
                </BOOButton>}
                <BOOButton variant='contained' onClick={() => confirmDeleteProps.clickOpen(true)} style={{ marginLeft: '10px'}} color='error'>
                    <Money />
                    &nbsp;REMOVE
                </BOOButton>
            </>
        )
    }, [isLocked])

    const walletAddress = userWallet?.address
    const walletBalance = userWallet?.amount
    const email = userCognitoInfo?.email
    const { level } = user.statistics
    const { rakePaidAmount } = user.statistics.global

	const info = useMemo(() => {
		return (
			<>
                <p>E-mail: {email}</p>
                <p>Wallet: {walletAddress}</p>
                <p>Balance: {`$${walletBalance}`}</p>
                <p>Level: {`Level ${level}`}</p>
                <p>Rake paid: {`$${rakePaidAmount}`}</p>
			</>
		)
	}, [isRefreshing, walletAddress, email, level, rakePaidAmount])

	return (
		<>
            <UserHeader user={user} clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			
            <SubSectionHeader title='Actions' />
            <div id='actions' className='section'>{actions}</div>
            
            <SubSectionHeader title='Info'/>
			<div id='info' className='section'>{info}</div>
            
            <SubSectionHeader title='Raw'/> 
			{user && <JsonView name='User' object={user} />}

			<ChangeNameView {...changeNameProps} />
			<ChangePasswordView {...changePasswordProps} />
			<ManualCreditView {...manualCreditProps} />
			<ConfirmDeleteView {...confirmDeleteProps} />
		</>
	)
}
