import React from 'react'
import { Cancel, Money, Save } from '@mui/icons-material'
import { FormControl, InputAdornment } from '@mui/material'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

export interface ConfirmDeleteFormData {
	confirmationCode: string | undefined
}

export type Props = {
	loading?: boolean
	onSubmit: SubmitHandler<ConfirmDeleteFormData>
	onCancel: () => void
}

const Page: React.FC<Props> = (props: Props) => {
	const { loading, onSubmit, onCancel } = props

	const formMethods = useForm<ConfirmDeleteFormData>({})

	return (
		<FormProvider {...formMethods}>
			<FormControl fullWidth id='confirm-delete-form' component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
				<div>This removes the user completely from the system.</div>
				<div>Please confirm:</div>
                <div className='flex mt-5 justify-end'>
                    <BOOButton startIcon={<Money />} type='submit' variant='contained' style={{ marginRight: '5px' }} loading={loading}>
                        REMOVE
                    </BOOButton>
                    <BOOButton startIcon={<Cancel />} variant='outlined' disabled={loading} onClick={onCancel}>
                        Cancel
                    </BOOButton>
			    </div>
			</FormControl>
		</FormProvider>
	)
}

export default Page