import React, { useState } from 'react'
import { DepositTransactionModel } from 'boorio-portal-commons/api'

export interface CryptoDepositListItemProps {
	deposit: DepositTransactionModel
}

export const CryptoDepositListItem: React.FC<CryptoDepositListItemProps> = ({ deposit }) =>
{
	const [focused, setFocused] = useState(false)

	const onMouseEnter = () => {
		setFocused(true)
	}
	const onMouseLeave = () => {
		setFocused(false)
	}

	return (
		<div className='boo-list-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<div className='boo-list-info'>
				<p className='index'>{deposit.id}</p>
				<a href={`https://bscscan.com/tx/${deposit.cryptoTransaction.hash}`}><p className='wide'>{deposit.cryptoTransaction.hash}</p></a>
				<p className='normal'>{deposit.minimumNotPassed ? 'YES' : '-'}</p>
				<p className='normal'>{deposit.conversionFailedTransaction !== undefined ? deposit.conversionFailedTransaction.hash : '-'}</p>
				<p className='normal'>{deposit.conversionFailedError !== undefined ? deposit.conversionFailedError : '-'}</p>
				<a href={`https://bscscan.com/tx/${deposit.cryptoTransaction.from}`}><p className='wide'>{deposit.cryptoTransaction.from}</p></a>
				{ deposit.cryptoTransaction.tokenContractAddress ? <a href={`https://bscscan.com/token/${deposit.cryptoTransaction.tokenContractAddress!}`}><p className='normal'>{deposit.cryptoTransaction.tokenSymbol}</p></a> : <p className='normal'>{deposit.cryptoTransaction.tokenSymbol}</p> }
				<p className='normal'>{deposit.cryptoTransaction.value}</p>
				<p className='normal'>{deposit.cryptoTransaction.status}</p>
				<p className='normal'>{deposit.cryptoTransaction.timeStamp}</p>
                <div className='spacer' />
            </div>
        </div>
	)
}
