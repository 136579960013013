import React from 'react'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { RoomsListItem } from './RoomsListItem'
import { RoomsHeader } from './RoomsHeader'
import { RoomsListHeader } from './RoomsListHeader'

export interface RoomsPageViewProps {
	rooms: PokerGameModel[],
	clickAddRoom: () => void,
	clickRefresh: () => void,
	clickRoom: (room: PokerGameModel) => void,
	isRefreshing: boolean
}

export const RoomsPageView : React.FC<RoomsPageViewProps> = ({ rooms, clickAddRoom, clickRefresh, clickRoom, isRefreshing }) =>
{
	const items = rooms.map((room) => <RoomsListItem key={room.id} room={room} clickRoom={clickRoom} />)

	return (
		<>
			<RoomsHeader clickRefresh={clickRefresh} clickAddRoom={clickAddRoom} isRefreshing={isRefreshing} roomsCount={rooms.length} />
			<div className='boo-list'>
				<RoomsListHeader />
				{items}
			</div>
		</>
	)
}
