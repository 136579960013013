import { Add } from '@mui/icons-material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { PokerPlayerBotType } from 'boorio-portal-commons/api'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

export interface SNGAddBotFormData {
	userId: string,
	botType: PokerPlayerBotType
}

interface Props {
	onSubmit: SubmitHandler<SNGAddBotFormData>
	onCancel: () => void
	loading?: boolean
}

const Page: React.FC<Props> = ({ onSubmit, onCancel, loading }) => {
	const formMethods = useForm<SNGAddBotFormData>({})

	return (
		<FormProvider {...formMethods}>
		    <FormControl id='sngaddbot-form' fullWidth component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
			<InputLabel id='bot-select-label'>Bot type</InputLabel>
                <Select
                    label-id='bot-select-label'
                    label='Bot'
                    title='Bot type'
                    {...formMethods.register('botType', { required: 'Bot type is required!' })}
                    error={Boolean(formMethods.formState.errors.botType)}
                    style={{ marginBottom: '10px' }}
                >
                    <MenuItem value='FOLD'>Fold</MenuItem>
                    <MenuItem value='PASSIVE'>Passive</MenuItem>
                    <MenuItem value='EASY'>Easy</MenuItem>
                    <MenuItem value='STUPIDAGGRESSIVE'>Stupid aggressive</MenuItem>
                    <MenuItem value='AGGRESSIVE'>Aggressive</MenuItem>
                    <MenuItem value='STUPIDBLUFFER'>Stupid bluffer</MenuItem>
                    <MenuItem value='BLUFFER'>Bluffer</MenuItem>
                </Select>
                <BOOTextInput
                    id='userid-input'
                    formField='userId'
                    formValidation={{ required: true }}
                    placeholder='bot-1'
                    label='UserId'
                    style={{ marginBottom: '10px' }}
                />
				<div className='flex mt-5 justify-end'>
                    <BOOButton type='submit' variant='contained' style={{ marginRight: '5px' }} loading={loading ?? false}>
                        <Add />
                        Add Bot
                    </BOOButton>
                    <BOOButton variant='outlined' onClick={onCancel} disabled={loading ?? false}>
                        Cancel
                    </BOOButton>
                </div>
            </FormControl>
        </FormProvider>
	)
}

export default Page
