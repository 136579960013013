import { useCallback } from 'react'
import { AdminApi, User } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { getBOOError } from 'boorio-portal-commons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../AppStore'
import { updateUser, refreshUsers, updateUserCognitoInfo, refreshUserCognitoInfos, updateUserWallet, refreshUserWallets, refreshBots, refreshBotWallets, clearUsers } from '../features/UsersStore'

export const useUser = (userId: string) => {
	const dispatch = useDispatch()
	const user = useSelector((root: RootState) => root.users.userDatas[userId]?.user)
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const updateAction = (user: User) => {
		dispatch(updateUser(user))
	}
	const action = useCallback(async () =>
    {
		try {
			const res = await api.adminUsersGet(userId)
			updateAction(res.data)
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { user, refreshUser: action, updateUser: updateAction }
}

export const useUserWallet = (userId: string) => {
	const dispatch = useDispatch()
	const wallet = useSelector((root: RootState) => root.users.userDatas[userId]?.wallet)
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const wallet = (await api.adminUsersGetUserWallet(userId)).data
			dispatch(updateUserWallet(wallet))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { userWallet: wallet, refreshUserWallet: action }
}

export const useUserCognitoInfo = (userId: string) => {
	const dispatch = useDispatch()
	const cognitoInfo = useSelector((root: RootState) => root.users.userDatas[userId]?.cognitoInfo)
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const cognitoInfo = (await api.adminUsersGetCognitoInfo(userId)).data
			dispatch(updateUserCognitoInfo(cognitoInfo))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { userCognitoInfo: cognitoInfo, refreshUserCognitoInfo: action }
}

export const useGetUserDatas = () => {
	const userDatas = useSelector((state: RootState) => state.users.userDatas)

	return { userDatas }
}

export const useUserByReferralCode = (referralCode: string) => {
	const dispatch = useDispatch()
	const user = useSelector((root: RootState) => Object.values(root.users.userDatas).find((u) => u.user.info.referralCode === referralCode)?.user)
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const res = await api.adminUsersFindByReferralCode(referralCode)
			dispatch(updateUser(res.data))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { user, refreshUser: action }
}
export const useRefreshUsers = () => {
	const dispatch = useDispatch()
	const users = useSelector((state: RootState) => Object.values(state.users.userDatas).map(d => d.user))
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const users = (await api.adminUsersGetAll()).data
			dispatch(refreshUsers(users))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { users, refreshUsers: action }
}
export const useRefreshUserWallets = () => {
	const dispatch = useDispatch()
	const wallets = useSelector((state: RootState) => Object.values(state.users.userDatas).map(d => d.wallet))
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const wallets = (await api.adminUsersGetWallets()).data
			dispatch(refreshUserWallets(wallets))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { wallets, refreshUserWallets: action }
}
export const useRefreshUserCognitoInfos = () => {
	const dispatch = useDispatch()
	const cognitoInfos = useSelector((state: RootState) => Object.values(state.users.userDatas).map(d => d.cognitoInfo))
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const cognitoInfos = (await api.adminUsersGetCognitoInfos()).data
			dispatch(refreshUserCognitoInfos(cognitoInfos))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { cognitoInfos, refreshUserCognitoInfos: action }
}

export const useGetBotDatas = () => {
	const botDatas = useSelector((state: RootState) => state.users.botDatas)

	return { botDatas }
}
export const useRefreshBots = () => {
	const dispatch = useDispatch()
	const bots = useSelector((state: RootState) => Object.values(state.users.botDatas).map(d => d.user))
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const users = (await api.adminUsersGetBots()).data
			dispatch(refreshBots(users))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { bots, refreshBots: action }
}
export const useRefreshBotWallets = () => {
	const dispatch = useDispatch()
	const botWallets = useSelector((state: RootState) => Object.values(state.users.userDatas).map(d => d.wallet))
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			const wallets = (await api.adminUsersGetWallets()).data
			dispatch(refreshBotWallets(wallets))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { botWallets, refreshBotWallets: action }
}
export const useRefreshUsersAfterRemoval = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)
	const { showError } = useReduxAlerts()

	const action = useCallback(async () =>
    {
		try {
			clearUsers()
			const users = (await api.adminUsersGetAll()).data
			dispatch(refreshUsers(users))
		} catch (e) {
			showError(getBOOError(e))
		}
	},
    [api])

	return { refreshUsersAfterRemoval: action }
}