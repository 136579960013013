import React from 'react'
import { PokerWaitingUserModel } from 'boorio-portal-commons/api'

export type WaitingUserViewProps = {
	user: PokerWaitingUserModel
}

export const WaitingUserView: React.FC<WaitingUserViewProps> = ({ user }) => {
	return (
		<div className='playerview-card'>
			<img className='profile-view' src={user.profile.info.avatar} alt='avatar' />
			<p style={{ fontWeight: 'bold', width: '70%' }}>
                {user.profile.info.name}
            </p>
		</div>
	)
}
