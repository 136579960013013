/* eslint-disable */
interface Images {
	readonly "cards": string
	readonly "iconBoorio": string
	readonly "imageBackground": string
	readonly "imageWallet": string
}

interface RType {
    images: Images
}

export const R: RType = {
    images: {
		"cards": process.env.PUBLIC_URL + "/images/cards",
		"iconBoorio": process.env.PUBLIC_URL + "/images/icon_boorio.png",
		"imageBackground": process.env.PUBLIC_URL + "/images/image_background.png",
		"imageWallet": process.env.PUBLIC_URL + "/images/image_wallet.png",
    }
}

