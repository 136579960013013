import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

export interface BotsHeaderProps {
	clickRefresh: () => void
	isRefreshing?: boolean
    usersCount: number
}

export const BotsHeader: React.FC<BotsHeaderProps> = ({ clickRefresh, isRefreshing, usersCount }) => {
	const refreshing = isRefreshing ?? false

	return (
		<SectionHeader title='Bots' subtitle={`${usersCount} total bots`}>
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}