import React from 'react'
import { User, UserWalletModel } from 'boorio-portal-commons/api'

export interface BotsListItemProps
{
	user: User,
	wallet?: UserWalletModel
}

export const BotsListItem: React.FC<BotsListItemProps> = ({ user, wallet }) => {
	return (
		<div className='boo-list-item'>
			<div className='boo-list-info'>
                <img id='user-avatar' src={user.info.avatar} alt='avatar' />
                <p className='index' style={{ marginLeft: '50px', textAlign: 'left' }}>{user.info.name}</p>
                <p className='wide'>{user.id}</p>
                <p className='normal'>{`$${wallet?.amount ?? 0}`}</p>
                <p className='normal'>{wallet?.address ?? '-'}</p>
                <p className='short'>{user.statistics.level}</p>
                <p className='short'>${user.statistics.global.rakePaidAmount}</p>
                <p className='short'>${user.statistics.global.winningsAmount}</p>
			</div>
		</div>
	)
}
