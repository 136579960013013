import React, { FC } from 'react'
import { Cancel, Save } from '@mui/icons-material'
import { FormControl, MenuItem, Select } from '@mui/material'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { UserLevelRequirementModel, UserLevelRequirementType } from 'boorio-portal-commons/api'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import './EditLevelForm.scss'

export interface EditLevelFormData {
	level?: number
	required: number
	type: UserLevelRequirementType
}

export interface EditLevelFormProps {
	mode: 'edit'
	level: UserLevelRequirementModel
}
export interface CreateLevelFormProps {
	mode: 'create'
}

export type Props = (EditLevelFormProps | CreateLevelFormProps) & {
	loading?: boolean
	onSubmit: SubmitHandler<EditLevelFormData>
	onCancel: () => void
}

const Page: FC<Props> = (props) => {
	const { mode, loading, onSubmit, onCancel } = props
	const level = (props as EditLevelFormProps)?.level

	const formMethods = useForm<EditLevelFormData>({
		defaultValues: {
			level: level?.level,
			required: level?.required,
			type: level?.type,
		},
	})

	return (
		<FormProvider {...formMethods}>
			<FormControl fullWidth id='edit-level-form' component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
				<BOOTextInput
					id='level-input'
					formField='level'
					formValidation={{ required: true, min: 0, valueAsNumber: true }}
					disabled={mode === 'edit'}
					placeholder='level'
					label='level'
				/>
				<BOOTextInput
					id='required-input'
					formField='required'
					label='Required'
					formValidation={{
						required: true,
						min: 0,
						valueAsNumber: true,
					}}
					placeholder='Required (number)'
				/>
				{/* <InputLabel id='type-select-label'>type</InputLabel> */}
				<Select
					labelId='type-select-label'
					variant='outlined'
					{...formMethods.register('type', {
						required: 'type is required!',
					})}
					defaultValue={level?.type}
					placeholder='Type'
				>
					<MenuItem value='HANDSPLAYED'>Hands played</MenuItem>
					<MenuItem value='HANDSWON'>Hands won</MenuItem>
					<MenuItem value='SPLITPOTSWON'>Splitpots won</MenuItem>
				</Select>
				<BOOButton startIcon={<Save />} type='submit' variant='contained' loading={loading}>
					Save
				</BOOButton>
				<BOOButton startIcon={<Cancel />} variant='outlined' disabled={loading} onClick={onCancel}>
					Cancel
				</BOOButton>
			</FormControl>
		</FormProvider>
	)
}

export default Page
