import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Select } from '@mui/material'
import React, { FC, useState } from 'react'
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { BOOButton, SectionHeader, BOOTextInput } from 'boorio-portal-commons/components'
import { AdminApi, PokerGameRakePercentModel, PokerGameTurnType, PokerGameType, PokerGameFormatType } from 'boorio-portal-commons/api'
import { MCSS, getBOOError } from 'boorio-portal-commons'
import StringUtils from 'boorio-portal-commons/utils/StringUtils'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useApi } from 'utils/api/useApi'
import { muiTheme } from '../../../utils/mui/theme'
import { useNavigateWrapper } from '../../../utils/Navigation'
import './CreateRoom.scss'

type TurnRakes = {
	[turnType in PokerGameTurnType as `${turnType}_Rake`]: number
}
type TurnRakeMaxs = {
	[turnType in PokerGameTurnType as `${turnType}_RakeMax`]: number
}
type CreateRoomFormData = {
	type: string
	roomName: string
	requiredLevel: number
	maxPlayers: number
	smallBlindAmount: string
	bigBlindAmount: string
	anteAmount: string
	minimumBuyInAmount: string
	maximumBuyInAmount: string
    isPotLimit: boolean
	timeToAct: number
	timeToMuckReveal: number
	tipDealerMultiplierAmount: string
	tableBackgroundImage: string
	tableMainImage: string
} & TurnRakes & TurnRakeMaxs

const formStyle : MCSS = {
	form: {
		margin: 'auto',
		background: muiTheme.palette.background.paper,
		paddingLeft: '30px',
		paddingRight: '30px',
		paddingTop: '20px',
		paddingBottom: '20px',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '8px',
		maxWidth: '1000px',
	},
	textField: {
		marginBottom: '10px',
	},
	submitButton: {
		marginTop: '30px',
		// alignSelf: 'center',
	},
	label: {
		color: 'white'
	}
}

const RakePercentSubForm = ({ turn }: { turn: PokerGameTurnType }) => {
	const turnTitle = StringUtils.ToUppercaseFirst(turn)
	return (
		<div>
			<BOOTextInput
				style={{ ...formStyle.textField, marginRight: '10px', width: '150px' }}
				formField={`${turn}_Rake`}
				type='number'
				formValidation={{ required: true, valueAsNumber: true, min: 0 }}
				label={`${turnTitle} rake`}
				placeholder='0'
				variant='standard'
				InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
			/>
		</div>
	)
}
const RakeMaxSubForm = ({ turn }: { turn: PokerGameTurnType }) => {
	const turnTitle = StringUtils.ToUppercaseFirst(turn)
	return (
		<div>
			<BOOTextInput
				style={{ ...formStyle.textField, marginRight: '10px', width: '150px' }}
				formField={`${turn}_RakeMax`}
				type='number'
				formValidation={{ required: true, valueAsNumber: true, min: 0 }}
				label={`${turnTitle} rake max`}
				placeholder='0.00'
				variant='standard'
				InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
			/>
		</div>
	)
}

const Form: React.FC = () => {
	const { showAlert } = useReduxAlerts()
	const navigate = useNavigateWrapper()
	const api = useApi(AdminApi)
	const [loading, setLoading] = useState(false)
	const formMethods = useForm<CreateRoomFormData>({
		defaultValues: {
			type: PokerGameType.Holdem,
			requiredLevel: 1,
			maxPlayers: 9,
			smallBlindAmount: '1.0',
			bigBlindAmount: '2.0',
			anteAmount: '0.0',
			minimumBuyInAmount: '5.0',
			maximumBuyInAmount: '20.0',
            isPotLimit: false,
			timeToAct: 30,
			timeToMuckReveal: 5,
			tipDealerMultiplierAmount: '0.05',
            tableBackgroundImage: '1',
            tableMainImage: '1',
			FLOP_Rake: 7,
			FLOP_RakeMax: 7,
			TURN_Rake: 7,
			TURN_RakeMax: 7,
			RIVER_Rake: 7,
			RIVER_RakeMax: 7
		},
	})
	const typeWatch = formMethods.watch('type')

	const turns : PokerGameTurnType[] = [PokerGameTurnType.Flop, PokerGameTurnType.Turn, PokerGameTurnType.River]

	const onSubmit: SubmitHandler<CreateRoomFormData> = (data) => {
		const rakes: PokerGameRakePercentModel[] = turns.map((it) => ({
			turn: it,
			percent: `${(data as any)[`${it}_Rake`] / 100}`,
			maxAmount: (data as any)[`${it}_RakeMax`] > 0 ? `${(data as any)[`${it}_RakeMax`]}` : undefined
		}))
		setLoading(true)
		api
			.adminRoomsCreate({
                type: data.type as PokerGameType,
                format: PokerGameFormatType.Cash,
				roomName: data.roomName,
				requiredLevel: data.requiredLevel,
				maxPlayers: data.maxPlayers,
				smallBlindAmount: data.smallBlindAmount,
				bigBlindAmount: data.bigBlindAmount,
				ante: data.anteAmount,
                allowedToRebuy: true,
				minimumBuyIn: data.minimumBuyInAmount,
				maximumBuyIn: data.maximumBuyInAmount,
                isPotLimit: PokerGameType.Omaha === data.type || data.isPotLimit,
				timeToAct: data.timeToAct,
				timeToMuckReveal: data.timeToMuckReveal,
				tipDealerMultiplierAmount: data.tipDealerMultiplierAmount,
				rakePercents: rakes,
                tableBackgroundImage: data.tableBackgroundImage,
                tableMainImage: data.tableMainImage
			})
			.then(() => {
				showAlert({ type: 'success', message: 'Room Created!' })
				navigate('/rooms')
			})
			.catch((err) => {
				showAlert({ type: 'error', message: getBOOError(err).message })
				setLoading(false)
			})
	}

	return (
		<FormProvider {...formMethods}>
			<form id='createroom-form' style={formStyle.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
				<h2 className='section-start'>General</h2>
				<Select
					labelId='type-select-label'
                    title='Game type'
                    {...formMethods.register('type', { required: 'game type is required!' })}
                    error={Boolean(formMethods.formState.errors.type)}
					defaultValue='HOLDEM'
				>
					<MenuItem value='HOLDEM'>Texas Hold&apos;em</MenuItem>
					<MenuItem value='OMAHA'>Omaha</MenuItem>
				</Select>
				<BOOTextInput
					id='name-field'
					style={formStyle.textField}
					formField='roomName'
					formValidation={{ required: true }}
					label='Room name'
					placeholder='Room name'
					variant='standard'
				/>
				<BOOTextInput
					id='level-field'
					style={formStyle.textField}
					formField='requiredLevel'
					type='number'
					formValidation={{
						min: 0,
						valueAsNumber: true,
						required: true,
					}}
					label='Required level'
					placeholder='Required level'
					variant='standard'
				/>
				<BOOTextInput
					id='players-field'
					style={formStyle.textField}
					formField='maxPlayers'
					formValidation={{
						min: 2,
						max: 9,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Max number of player'
					placeholder='Max number of players'
					variant='standard'
				/>
				<BOOTextInput
					id='timetoact-field'
					style={formStyle.textField}
					formField='timeToAct'
					formValidation={{
						min: 10,
						max: 60,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Time to act'
					placeholder='Time to act'
					InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}
					variant='standard'
				/>
				<BOOTextInput
					id='timetomuck-field'
					style={formStyle.textField}
					formField='timeToMuckReveal'
					formValidation={{
						min: 0,
                        max: 15,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Time to muck'
					placeholder='Time to muck'
					InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}
					variant='standard'
				/>
				<h2 className='section-start'>Blinds</h2>
				<BOOTextInput
					id='smallblind-field'
					style={formStyle.textField}
					formField='smallBlindAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Small blind'
					placeholder='Small blind'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='bigblind-field'
					style={formStyle.textField}
					formField='bigBlindAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true
					}}
					type='number'
					label='Big blind'
					placeholder='Big blind'
                    inputProps={{ step: 0.1 }}
                    // TODO - re-add this when 6.0 comes out
					// InputProps={{ 
                    //     inputProps
                    //     startAdornment: <InputAdornment position='start'>$</InputAdornment>  }}
					variant='standard'
				/>
				<BOOTextInput
					id='ante-field'
					style={formStyle.textField}
					formField='anteAmount'
					formValidation={{
						min: 0.0,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Ante'
					placeholder='Ante'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<h2 className='section-start'>Buy In Limits</h2>
				<BOOTextInput
					id='minbuyin-field'
					style={formStyle.textField}
					formField='minimumBuyInAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Min Buy In'
					placeholder='Min buy in'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<BOOTextInput
					id='maxbuyin-field'
					style={formStyle.textField}
					formField='maximumBuyInAmount'
					formValidation={{
						min: 0.1,
						valueAsNumber: true,
						required: true,
					}}
					type='number'
					label='Max Buy In'
					placeholder='Max buy in'
                    inputProps={{ step: 0.1 }}
					variant='standard'
				/>
				<h2 className='section-start'>Rakes</h2>
				<div id='create-room-rakes' className='horizontal-list'>
					<RakePercentSubForm turn={PokerGameTurnType.Flop} />
					<RakePercentSubForm turn={PokerGameTurnType.Turn} />
					<RakePercentSubForm turn={PokerGameTurnType.River} />
				</div>
				<div id='create-room-rakemaxs' className='horizontal-list'>
					<RakeMaxSubForm turn={PokerGameTurnType.Flop} />
					<RakeMaxSubForm turn={PokerGameTurnType.Turn} />
					<RakeMaxSubForm turn={PokerGameTurnType.River} />
				</div>
                {typeWatch !== PokerGameType.Omaha && <FormControlLabel style={formStyle.label} control={<Checkbox />} label='Pot Limit' onChange={(_, checked) => formMethods.setValue('isPotLimit', checked)}/>}
				<BOOTextInput
					id='tablebackgroundimage-field'
					style={formStyle.textField}
					formField='tableBackgroundImage'
					formValidation={{ required: true }}
					label='Table Background Image'
					placeholder='Image name'
					variant='standard'
				/>
                <BOOTextInput
                    id='tablemainimage-field'
                    style={formStyle.textField}
                    formField='tableMainImage'
                    formValidation={{ required: true }}
                    label='Table Main Image'
                    placeholder='Image name'
                    variant='standard'
                />
				<BOOButton type='submit' style={formStyle.submitButton} form='createroom-form' variant='contained' loading={loading}>
					Create
				</BOOButton>
			</form>
		</FormProvider>
	)
}

const page: FC = () => {
	return (
		<>
			<SectionHeader title='Create Room' subtitle='Create a poker game room' />
			<Form />
		</>
	)
}

export default page
