import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WithdrawalRequestModel } from 'boorio-portal-commons/api'

export interface Withdrawals {
    withdrawals: WithdrawalRequestModel[]
}

const initialState: Withdrawals = {
    withdrawals: []
}

export const withdrawalsSlice = createSlice({
	name: 'Withdrawals',
	initialState,
	reducers: {
		refreshWithdrawals: (state, action: PayloadAction<WithdrawalRequestModel[]>) => {
			state.withdrawals = action.payload
		},
		updateWithdrawal: (state, action: PayloadAction<WithdrawalRequestModel>) => {
			state.withdrawals = state.withdrawals.filter((it) => it.id !== action.payload.id)
			state.withdrawals = [...state.withdrawals, action.payload]
		},
	},
})

export const { refreshWithdrawals, updateWithdrawal } = withdrawalsSlice.actions

export default withdrawalsSlice.reducer
