import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminMessageModel, LocalisationsModel } from 'boorio-portal-commons/api'

export interface Admin {
	message: AdminMessageModel,
	localisations: LocalisationsModel
}

const initialState: Admin = {
	message: { id: '', message: '', valid: true },
	localisations: { id: '', values: {} }
}

export const adminSlice = createSlice({
	name: 'Admin',
	initialState,
	reducers: {
		refreshMessage: (state, action: PayloadAction<AdminMessageModel>) => {
			state.message = action.payload
		},
		refreshLocalisations: (state, action: PayloadAction<LocalisationsModel>) => {
			state.localisations = action.payload
		}
	},
})

export const { refreshMessage, refreshLocalisations } = adminSlice.actions

export default adminSlice.reducer
