import React from 'react'
import { BOOButton, RefreshButton, SectionHeader } from 'boorio-portal-commons/components'
import { AndroidRounded } from '@mui/icons-material'

export interface UsersHeaderProps {
	clickBots: () => void
	clickRefresh: () => void
	isRefreshing?: boolean
    usersCount: number
}

export const UsersHeader: React.FC<UsersHeaderProps> = ({ clickBots, clickRefresh, isRefreshing, usersCount }) => {
	const refreshing = isRefreshing ?? false

	return (
		<SectionHeader title='Users' subtitle={`${usersCount} total users`}>
			<BOOButton variant='contained' onClick={clickBots}>
				<AndroidRounded />
				&nbsp;Bots
			</BOOButton>
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}