import React from 'react'
import ReactDom from 'react-dom'
import './index.scss'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { Helmet } from 'react-helmet'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from './reportWebVitals'
import Root from './pages/Root'
import { store } from './stores/AppStore'
import { muiTheme } from './utils/mui/theme'

const persistor = persistStore(store)

ReactDom.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<ThemeProvider theme={muiTheme}>
						<Helmet>
							<title>Boorio Poker Admin Portal</title>
						</Helmet>
                            <Root />
					</ThemeProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined)
