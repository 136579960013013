import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AdminMessageModel } from 'boorio-portal-commons/api'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import { BOOConfirmationModal } from 'views/Modal/BOOConfirmationModal'

export type InvalidateAdminMessageViewProps = {
	message: AdminMessageModel,
	clickSubmit: SubmitHandler<AdminMessageModel>
} & CommonModalProps

export const InvalidateAdminMessageView : React.FC<InvalidateAdminMessageViewProps> = ({ id, message, clickSubmit, clickOpen }) =>
{
	return (
		<BOOConfirmationModal
			id={id}
			title='Confirm Invalidate'
			description='Do you want to invalidate the latest message? Users will stop seeing the admin message prompt in the clients.'
			okTitle='Invalidate'
			cancelTitle='Cancel'
			onClickOk={() => clickSubmit(message)}
			onDismiss={() => clickOpen(false)}
		/>
	)
}