import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../AppStore'
import { open as openModal, close as closeModal } from '../features/Modals'

export const useModal = (id: string): [boolean, (open: boolean) => void] => {
	const dispatch = useDispatch()
	const ls = useSelector((root: RootState) => root.modals.open)

	const open = useMemo(() => ls.includes(id), [ls, id])

	const setOpen = useCallback(
		(open: boolean) => {
			dispatch(open ? openModal(id) : closeModal(id))
		},
		[dispatch, id],
	)

	return [open, setOpen]
}
