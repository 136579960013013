import React from 'react'
import { BOOButton, RefreshButton, SectionHeader } from 'boorio-portal-commons/components'
import { AddCircle } from '@mui/icons-material'

export interface RoomsHeaderProps {
	clickAddRoom: () => void,
	clickRefresh: () => void,
	isRefreshing: boolean,
    roomsCount: number
}

export const RoomsHeader: React.FC<RoomsHeaderProps> = ({ clickAddRoom, clickRefresh, isRefreshing, roomsCount }) =>
{
	return (
        <SectionHeader title={`Rooms (${roomsCount})`}>
            <BOOButton variant='contained' onClick={clickAddRoom}>
                <AddCircle />
                &nbsp;Create Room
            </BOOButton>
            <RefreshButton variant='text' loading={isRefreshing} onClick={clickRefresh} />
        </SectionHeader>
	)
}