import { Button } from '@mui/material'
import * as React from 'react'

export default function (props: {}): JSX.Element {
	return (
		<div>
			<h1 style={{ color: 'white' }}>Oops: Page not found</h1>
		</div>
	)
}
