import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { User, UserCognitoInfoModel, UserWalletModel } from 'boorio-portal-commons/api'

export type UserData = {
    user: User,
    wallet?: UserWalletModel,
    cognitoInfo?: UserCognitoInfoModel
}

export interface UserDatas {
    userDatas: { [userId: string] : UserData },
    botDatas: { [userId: string] : UserData }
}

const initialState: UserDatas = {
    userDatas: {},
    botDatas: {}
}

export const usersSlice = createSlice({
	name: 'UserDatas',
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<User>) => {
			const user = action.payload
            if (state.userDatas[user.id]){
                state.userDatas[user.id].user = user
            }else{
                state.userDatas[user.id] = { user }
            }
		},
		updateUserWallet: (state, action: PayloadAction<UserWalletModel>) => {
			const wallet = action.payload
            if (state.userDatas[wallet.userId]){
                state.userDatas[wallet.userId].wallet = wallet
            }
		},
		updateUserCognitoInfo: (state, action: PayloadAction<UserCognitoInfoModel>) => {
			const cognitoInfo = action.payload
            if (state.userDatas[cognitoInfo.id]){
                state.userDatas[cognitoInfo.id].cognitoInfo = cognitoInfo
            }
		},
		refreshUsers: (state, action: PayloadAction<User[]>) => {
			const users = action.payload
            const datasMap = { ...state.userDatas }
            users.forEach((user) => {
                const old = datasMap[user.id]
                datasMap[user.id] = { 
                    user,
                    wallet: old?.wallet,
                    cognitoInfo: old?.cognitoInfo
                }
            })
            state.userDatas = datasMap
		},
		refreshUserWallets: (state, action: PayloadAction<UserWalletModel[]>) => {
			const wallets = action.payload
            const datasMap = { ...state.userDatas }
            wallets.forEach((wallet) => {
                const old = datasMap[wallet.userId]
                if (old){
                    datasMap[wallet.userId] = { 
                        user: old.user,
                        wallet,
                        cognitoInfo: old.cognitoInfo
                    }
                }
            })
            state.userDatas = datasMap
		},
		refreshUserCognitoInfos: (state, action: PayloadAction<UserCognitoInfoModel[]>) => {
			const cognitoInfos = action.payload
            const datasMap = { ...state.userDatas }
            cognitoInfos.forEach((cognitoInfo) => {
                const old = datasMap[cognitoInfo.id]
                if (old){
                    datasMap[cognitoInfo.id] = { 
                        user: old.user,
                        wallet: old.wallet,
                        cognitoInfo
                    }
                }
            })
            state.userDatas = datasMap
		},
		clearUsers: (state) => {
			state.userDatas = {}
		},
		refreshBots: (state, action: PayloadAction<User[]>) => {
			const users = action.payload
            const datasMap = { ...state.botDatas }
            users.forEach((user) => {
                const old = datasMap[user.id]
                datasMap[user.id] = { 
                    user,
                    wallet: old?.wallet,
                    cognitoInfo: old?.cognitoInfo
                }
            })
            state.botDatas = datasMap
		},
		refreshBotWallets: (state, action: PayloadAction<UserWalletModel[]>) => {
			const wallets = action.payload
            const datasMap = { ...state.botDatas }
            wallets.forEach((wallet) => {
                const old = datasMap[wallet.userId]
                if (old){
                    datasMap[wallet.userId] = { 
                        user: old.user,
                        wallet,
                        cognitoInfo: old.cognitoInfo
                    }
                }
            })
            state.botDatas = datasMap
		},
	},
})

export const { updateUser, updateUserWallet, updateUserCognitoInfo, refreshUsers, refreshUserWallets, refreshUserCognitoInfos, clearUsers, refreshBots, refreshBotWallets } = usersSlice.actions

export default usersSlice.reducer
