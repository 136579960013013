import React, { FC } from 'react'
import { PokerPlayerModel } from 'boorio-portal-commons/api'

export type PlayerViewProps = {
	player: PokerPlayerModel
}

export const PlayerView: FC<PlayerViewProps> = ({ player }) => {
	return (
		<div className='playerview-card'>
			<img className='profile-view' src={player.profile.info.avatar} alt='avatar' />
			<p style={{ fontWeight: 'bold', width: '30%' }}>
                {player.profile.info.name}
            </p>
			<div className='spacer' />
			<p style={{ width: '10%' }}>
                Money: ${player.moneyAmount}
            </p>
			<p style={{ width: '10%' }}>
                Current Bet: ${player.currentBetAmount}
            </p>
			<p style={{ width: '10%' }}>
                RakePaid: {player.statistics.rakePaidAmount}
            </p>
			<p style={{ width: '10%' }}>
				{player.status}
			</p>
		</div>
	)
}
