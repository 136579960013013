import React, { useEffect, useMemo, useState } from 'react'
import { SubSectionHeader } from 'boorio-portal-commons/components'
import { CryptoTransactionModel } from 'boorio-portal-commons/api'
import { useUnknownTransactions } from '../../stores/hooks/cryptoHooks'
import '../../index.scss'
import { CryptoHeader } from './CryptoHeader'
import { CryptoTransactionListItem } from './CryptoTransactionListItem'
import { CryptoTransactionListHeader } from './CryptoTransactionListHeader'
import CryptoNavBar from './CryptoNavBar'


export default () => {
	const [isRefreshing, setRefreshing] = useState(false)
	const { transactionsUnknown, refreshTransactionsUnknown } = useUnknownTransactions()

	const clickRefresh = () => {
		setRefreshing(true)

		refreshTransactionsUnknown()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])


    const unknownTransactions : CryptoTransactionModel[] = transactionsUnknown.map(t => t.cryptoTransaction)

	const unknownTransactionsViews = useMemo(
		() =>
        unknownTransactions.map((it) => (
				<CryptoTransactionListItem transaction={it} />
			)),
		[unknownTransactions],
	)

	return (
		<>
			<CryptoNavBar/>
        	<CryptoHeader clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
        
			<SubSectionHeader title='All Unknown Transactions' />
			<div className='boo-list'>
				<CryptoTransactionListHeader />
				{unknownTransactionsViews}
			</div>
		</>
	)
}
