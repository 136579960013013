import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

// TODO: make a standard header
export interface CryptoHeaderProps {
	clickRefresh: () => void
	isRefreshing?: boolean
}

export const CryptoHeader: React.FC<CryptoHeaderProps> = ({ clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader
            title='Crypto'
        >
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}