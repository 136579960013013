import React from 'react'

export default () => {
	return (
		<>
			<h1>Unauthorized access</h1>
			<h2>You do not have permissions to access the requested page</h2>
		</>
	)
}
