import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminApi, PokerGameFormatType, PokerGameModel } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useGetRoomById, useKickRoom, useRefreshRoom, useRefreshRooms } from '../../../stores/hooks/roomHooks'
import { AddBotFormData } from './AddBot/AddBotForm'
import '../../../index.scss'
import './room-detail.scss'
import { useModal } from '../../../stores/hooks/modalHooks'
import { ChangeNameFormData } from './ChangeName/ChangeNameForm'
import { RoomPageView } from './RoomPageView'

type RoomPagePathParams = { id: string }

export default () => {
	const { id } = useParams<RoomPagePathParams>()
	const room = useGetRoomById(id!)
	const refreshRooms = useRefreshRooms(PokerGameFormatType.Cash).refresh
	const refreshRoom = useRefreshRoom()
	const kickRoom = useKickRoom()
    const { catchAlert } = useReduxAlerts()

	const api = useApi(AdminApi)
	const [isRefreshing, setRefreshing] = useState(false)
    const navigate = useNavigate()

    const idAddBot = 'addBot'
	const [, openAddBotModal] = useModal(idAddBot)
	const [isAddBotLoading, setAddBotLoading] = useState(false)

    const idChangeName = 'changeName'
	const [, openChangeNameModal] = useModal(idChangeName)
	const [isChangeNameLoading, setChangeNameLoading] = useState(false)

    const idKickPlayers = 'kickPlayers'
	const [, openKickPlayersModal] = useModal(idKickPlayers)
	const [isKickPlayersLoading, setKickPlayersLoading] = useState(false)

    const idDelete = 'delete'
	const [, openDeleteModal] = useModal(idDelete)
	const [isDeleteLoading, setDeleteLoading] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)
		refreshRoom(id!).finally(() => {
			setRefreshing(false)
		})
	}

	useEffect(() => {
		clickRefresh()
	}, [])

	const onConfirmDelete = () => {
		setDeleteLoading(true)
        catchAlert(
            api.adminRoomsDelete(room!.id),
            'Room deleted',
            () => { navigate('/rooms') }
        )
        .finally(() => {
            setDeleteLoading(false)
            openDeleteModal(false)
            refreshRooms()
        })
	}

	const onConfirmKickPlayers = (room: PokerGameModel) => {
		setKickPlayersLoading(true)

        catchAlert(
            kickRoom(room.id),
            'All users have been kicked and their money refunded'
        )
        .finally(() => {
            setKickPlayersLoading(false)
            openKickPlayersModal(false)
        })
	}

	const onSubmitNewBot = ({ userId, botType, moneyAmount }: AddBotFormData) => {
		setAddBotLoading(true)

        catchAlert(
            api.adminRoomsAddBot(room!.id, userId, botType, { value: `${moneyAmount}` })
            .then(async () => {
                // eslint-disable-next-line no-promise-executor-return
                await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
            })
            .then(() => refreshRooms()),
            'Bot added successfuly'
        )
        .finally(() => {
            setAddBotLoading(false)
            openAddBotModal(false)
        })
	}

	const onSubmitChangeName = ({ name }: ChangeNameFormData) => {
        setChangeNameLoading(true)

        catchAlert(
            api.adminRoomsChangeName(room!.id, { value: `${name}` })
            .then(async () => {
                // eslint-disable-next-line no-promise-executor-return
                await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
            })
            .then(() => refreshRooms()),
            'Name changed successfuly'
        )
        .finally(() => {
            setChangeNameLoading(false)
            openChangeNameModal(false)
        })
	}

    const onHiddenChange = (hidden: boolean) => {
        setRefreshing(true)
        if (hidden){
            catchAlert(
                api.adminRoomsHide(room!.id)
                .then(async () => {
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
                })
                .then(() => refreshRooms()),
                'Room hidden successfuly'
            )
            .finally(() => {
                setRefreshing(false)
            })
        }else{
            catchAlert(
                api.adminRoomsShow(room!.id)
                .then(async () => {
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise<void>((fulfill) => setTimeout(() => fulfill(), 2000))
                })
                .then(() => refreshRooms()),
                'Room shown successfuly'
            )
            .finally(() => {
                setRefreshing(false)
            })
        }
    }

	return <RoomPageView 
        room={room!}
        clickRefresh={clickRefresh}
        isRefreshing={isRefreshing}
        changeHidden={onHiddenChange}
        addBotProps={{
            id: idAddBot,
            clickSubmit: onSubmitNewBot,
            clickOpen: openAddBotModal,
            loading: isAddBotLoading
        }}
        changeNameProps={{
            id: idChangeName,
            room: room!,
            clickSubmit: onSubmitChangeName,
            clickOpen: openChangeNameModal,
            loading: isChangeNameLoading
        }}
        roomKickPlayersProps={{
            id: idKickPlayers,
            room: room!,
            clickSubmit: onConfirmKickPlayers,
            clickOpen: openKickPlayersModal,
            loading: isKickPlayersLoading
        }}
        roomDeleteProps={{
            id: idDelete,
            room: room!,
            clickSubmit: onConfirmDelete,
            clickOpen: openDeleteModal,
            loading: isDeleteLoading
        }}
    />
}
