import * as React from 'react'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { getBOOError } from 'boorio-portal-commons'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { setError } from '../stores/features/ActiveUser'
import { R } from '../R/R'
import { useLogin } from '../stores/hooks/useActiveUser'
import { useNavigateWrapper } from '../utils/Navigation'
import './Login.scss'

const mb = (px: number) => ({ marginBottom: `${px}px` })

type LoginFormData = {
	username: string
	password: string
}

const formStyle: React.CSSProperties = {
	borderStyle: 'solid',
	borderColor: '#F510FF',
	borderWidth: '3px',
	backgroundImage: `url(${R.images.imageBackground})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
}

export default (): JSX.Element =>
{
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const navigate = useNavigateWrapper()
	const formMethods = useForm<LoginFormData>()
	const { login, activeUser } = useLogin()

	const onSubmit: SubmitHandler<LoginFormData> = ({ username, password }) => {
		if (username.length === 0 || password.length === 0) {
			showAlert({ type: 'error', message: 'missing credentials' })
			return
		}
		login({ username, password })
	}

	useEffect(() => {
		if (activeUser.error) {
			const e = getBOOError(activeUser.error)
			showAlert({ type: 'error', message: e.message })
			dispatch(setError(undefined))
		}
	}, [activeUser, dispatch, formMethods, navigate, showAlert])

	return (
		<div>
			<div id='login-parent' className='flex flex-col items-center absolute h-screen w-screen'>
				<img src={R.images.iconBoorio} alt='icon' style={{ marginTop: '100px', width: '300px', marginBottom: '100px' }} />
				<FormProvider {...formMethods}>
					<form
						id='login-box'
						style={formStyle}
						className='flex flex-col justify-center self-center ml-auto mr-auto mb-auto p-20 rounded-lg'
						onSubmit={formMethods.handleSubmit(onSubmit)}
					>
						<BOOTextInput
							id='username-field'
							formField='username'
							style={mb(5)}
							className=''
							variant='outlined'
							label='username'
							placeholder='username'
						/>
						<BOOTextInput
							id='password-field'
							formField='password'
							type='password'
							style={mb(10)}
							className=''
							variant='outlined'
							label='password'
							placeholder='password'
						/>
						<BOOButton type='submit' form='login-box' id='login-button' variant='contained' loading={activeUser.loading}>
							Login
						</BOOButton>
						<a className='forgot-password-button' href='/forgot-password'>
							Forgot password?
						</a>
					</form>
				</FormProvider>
			</div>
		</div>
	)
}
