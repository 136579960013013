import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import SNGAddBotForm, { SNGAddBotFormData } from './SNGAddBotForm'

export type SNGAddBotViewProps = {
	clickSubmit: SubmitHandler<SNGAddBotFormData>
} & CommonModalProps

export const SNGAddBotView : React.FC<SNGAddBotViewProps> = ({ id, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<SNGAddBotForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}
