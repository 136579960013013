import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import ChangePasswordForm, { ChangePasswordFormData } from 'pages/Users/ChangePassword/ChangePasswordForm'
import { CommonModalProps } from 'views/Modal/CommonModalProps'

export type ChangePasswordViewProps = {
	clickSubmit: SubmitHandler<ChangePasswordFormData>
} & CommonModalProps

export const ChangePasswordView : React.FC<ChangePasswordViewProps> = ({ id, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<ChangePasswordForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}