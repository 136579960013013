import React, { FC } from 'react'
import { BOOAlertStack, useAlerts } from 'boorio-portal-commons/components'
import { useEvents } from 'stores/hooks/eventsHooks'

export const ReduxAlertStack: FC<{}> = () =>
{
	const { events, showAlert, removeAlert } = useEvents()
	return <BOOAlertStack alerts={events} showAlert={showAlert} closeAlert={removeAlert} />
}
export const useReduxAlerts = () =>
{
	const events = useEvents()
	return useAlerts({ alerts: events.events, showAlert: events.showAlert, closeAlert: events.removeAlert })
}
