import React from 'react'

export const SNGsListHeader: React.FC = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='normal' style={{ marginLeft: '0px' }}>Type</p>
                <p className='index'>Name</p>
                <p className='normal'>Cost | Start Chips</p>
                <p className='normal'>1st Prize | 2nd Prize</p>
                <p className='short'>Starting SB | BB</p>
                <p className='normal'>Max SB | #Games to x2</p>
                <p className='short'>Req Lvl</p>
                <p className='normal'>Players | Participants</p>
                <p className='normal'>Status</p>
                <p className='normal'>Rake Current | Total</p>
                <p className='short'>Total Games</p>
                <p className='normal'>Avg Pot | Players in Pot</p>
            </div>
		</div>
	)
}
