import React from 'react'
import { BOOButton } from 'boorio-portal-commons/components'
import { Add } from '@mui/icons-material'

export interface LevelRequirementListHeaderProps
{
	onClickAdd: () => void
}

export const LevelRequirementListHeader: React.FC<LevelRequirementListHeaderProps> = ({ onClickAdd }) => {
	return (
		<div className='boo-list-item boo-list-header'>
			<div className='boo-list-info'>
				<p className='index'>Level</p>
				<p className='wide'>Requirement</p>
			</div>
			<div className='boo-list-options'>
				<BOOButton style={{ width: 'auto' }} onClick={onClickAdd}>
					<Add />
					Add new level
				</BOOButton>
			</div>
		</div>
	)
}