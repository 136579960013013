import React from 'react'

export const CryptoTransactionListHeader : React.FC<{}> = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='index'>HASH</p>
                <p className='wide'>From</p>
                <p className='normal'>Token</p>
                <p className='normal'>Value</p>
                <p className='normal'>Status</p>
                <p className='normal'>Timestamp</p>
            </div>
		</div>
	)
}
