import React from 'react'

export const RoomsListHeader: React.FC = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='normal' style={{ marginLeft: '0px' }}>Type</p>
                <p className='index'>Room Name</p>
                <p className='normal'>SB | BB | Limit | Ante</p>
                <p className='short'>Rake %</p>
                <p className='normal'>Buy In Min | Max</p>
                <p className='short'>Req Lvl</p>
                <p className='short'>Players</p>
                <p className='normal'>Status</p>
                <p className='short'>Total Games</p>
                <p className='normal'>Avg Pot | Players in Pot</p>
            </div>
		</div>
	)
}
