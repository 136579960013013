import React from 'react'
import { Cancel, Save } from '@mui/icons-material'
import { FormControl } from '@mui/material'
import { BOOButton, BOOTextInput } from 'boorio-portal-commons/components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

export interface UpdateLocalisationFormData {
	key: string,
	value: string
}

export type Props = {
	loading?: boolean
	onSubmit: SubmitHandler<UpdateLocalisationFormData>
	onCancel: () => void
}

const Page: React.FC<Props> = (props: Props) => {
	const { loading, onSubmit, onCancel } = props

	const formMethods = useForm<UpdateLocalisationFormData>({})

	return (
		<FormProvider {...formMethods}>
			<FormControl fullWidth id='update-localisation-form' component='form' onSubmit={formMethods.handleSubmit(onSubmit)}>
				
                <BOOTextInput
					id='key-input'
					formField='key'
					formValidation={{ required: true }}
					label='Key'
				/>
                <BOOTextInput
					id='value-input'
					formField='value'
					formValidation={{ required: true }}
					label='Value'
				/>
                <div className='flex mt-5 justify-end'>
                    <BOOButton startIcon={<Save />} type='submit' variant='contained' style={{ marginRight: '5px' }} loading={loading}>
                        Submit
                    </BOOButton>
                    <BOOButton startIcon={<Cancel />} variant='outlined' disabled={loading} onClick={onCancel}>
                        Cancel
                    </BOOButton>
			    </div>
			</FormControl>
		</FormProvider>
	)
}

export default Page