import { CircularProgress, IconButton } from '@mui/material'
import { getBOOError } from 'boorio-portal-commons'
import { RefreshButton } from 'boorio-portal-commons/components'
import { R } from 'R/R'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { useRefreshBalance } from '../../stores/hooks/cryptoHooks'

export const CryptoWallet = () => {
	const navigate = useNavigate()
	const { showError } = useReduxAlerts()
	const { balance, refreshBalance } = useRefreshBalance()
	const [isRefreshing, setRefreshing] = useState(false)
    
	const clickRefresh = () => {
		setRefreshing(true)

		refreshBalance()
        .catch((err) => {
            // TODO: make a util catchError(show: boolean = true)
            showError(getBOOError(err)) 
        })
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const handleClick = useCallback(() => {
		navigate('/crypto')
	}, [])
    
    const secureBalance = Number(balance?.secureBalance ?? '0.0').toFixed(2)
    const rakeBalance = Number(balance?.rakeBalance ?? '0.0').toFixed(2)

    const tooltip = (
        <RefreshButton variant='text' loading={isRefreshing} onClick={clickRefresh} />
    )
	const children = useMemo(() => {
		return (
			<>
				{isRefreshing && <CircularProgress />}

                <IconButton
                    size='large'
                    aria-label='wallet'
                    aria-controls='crypto-wallet'
                    onClick={() => handleClick()}
                    color='primary'
                    style={{  height: '100%',  marginBottom: '20px' , display: 'inline-block' }}
                >
                    <img id='wallet-icon' src={R.images.imageWallet} alt='wallet' style={{ height: '100%' }}/>
                </IconButton>
                <div style={{ display: 'inline-block', height: '100%', marginRight:'10px' }}>
                    <p>Secure: ${secureBalance}</p>
                    <p>Rake: ${rakeBalance}</p>
                </div>
			</>
		)
	}, [isRefreshing, secureBalance, rakeBalance])
	return (
		<div id='crypto-wallet' style={{  height: '100%'}}>
            {children}
		</div>
	)
}
