import React, { useEffect, useState } from 'react'
import { PokerGameFormatType, PokerGameModel } from 'boorio-portal-commons/api'
import { useRefreshRooms } from '../../stores/hooks/roomHooks'
import { useNavigateWrapper } from '../../utils/Navigation'
import { RoomsPageView } from './RoomsPageView'
import '../../index.scss'

export default () => {
	const navigate = useNavigateWrapper()
	const { rooms, refresh } = useRefreshRooms(PokerGameFormatType.Cash)
	const [isRefreshing, setRefreshing] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)
		refresh().finally(() => {
			setRefreshing(false)
		})
	}

	useEffect(() => {
		clickRefresh()
	}, [])

	const clickRoom = (room: PokerGameModel) => {
		navigate(`/rooms/${room.id}`)
	}

	const clickAdd = () => {
		navigate('/rooms/create')
	}

	return <RoomsPageView rooms={rooms} clickAddRoom={clickAdd} clickRefresh={clickRefresh} clickRoom={clickRoom} isRefreshing={isRefreshing} />
}
