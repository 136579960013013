import React, { FC } from 'react'
import { SectionHeader, JsonView } from 'boorio-portal-commons/components'
import { useAdmin } from '../../stores/hooks/useActiveUser'
import './Profile.scss'

const Page: FC = () => {
	const admin = useAdmin()

	return (
		<>
			<SectionHeader title='Profile' subtitle='Your profile' />
			<JsonView name='Profile' object={admin ?? {}} />
		</>
	)
}

export default Page
