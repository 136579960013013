import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { APIConfigType, createClient, IApi } from 'boorio-portal-commons/api'
import { RootState } from '../../stores/AppStore'
import apiKeys from '../../config/apiKeys.json'

export function useApi<T>(ApiClass: IApi<T>) : T
{
	const admin = useSelector((state: RootState) => state.activeUser?.user)
	const token = admin?.tokens?.idToken
	const { host } = apiKeys
	const timeout = 1_000 * 60 * 3
    const headers = {
        'client-name' : apiKeys['client-name'],
        'client-version' : apiKeys['client-version'],
    }

	const config : APIConfigType = { token, host, timeout, headers }

	const api = useMemo(() => {
		const client = createClient(config)
		return new ApiClass(undefined, host, client)
	}, [config])

	return api
}
