import React from 'react'

export const CryptoConversionListHeader : React.FC<{}> = () => {
	return (
		<div className='boo-list-item boo-list-header'>
            <div className='boo-list-info'>
                <p className='index'>Hash</p>
                <p className='wide'>DepositId</p>
                <p className='wide'>Deposit From Address</p>
            </div>
		</div>
	)
}
