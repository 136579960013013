import React from 'react'

export const UsersListHeader: React.FC = () => {
	return (
		<div className='boo-list-item boo-list-header'>
			<div className='boo-list-info'>
				<p className='index' style={{ marginLeft: '100px', textAlign: 'left' }}>Name</p>
				<p className='wide'>Id</p>
				<p className='wide'>E-mail</p>
				<p className='normal'>Wallet</p>
				<p className='short'>Level</p>
				<p className='short'>Hands Played</p>
				<p className='short'>Rake Paid</p>
				<p className='short'>Win $</p>
				<p className='short'>Ref By:</p>
				<p className='normal'>Join Date</p>
			</div>
		</div>
	)
}