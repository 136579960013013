import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { NavBar } from 'boorio-portal-commons/components'
import { when, whenVoid } from 'boorio-portal-commons'
import { R } from '../R/R'
import { logout } from '../stores/features/ActiveUser'
import { CryptoWallet } from './CryptoWallet/CryptoWallet'

type Routes<T extends string> = {
	[value in T]: RegExp
}

const MENU_ITEMS = ['profile', 'logout'] as const
const NAV_BUTTONS = ['Rooms', 'SNGs', 'Users', 'Levels', 'Withdrawals', 'Other'] as const

type MenuItemsType = typeof MENU_ITEMS[number]
type NavButtonsType = typeof NAV_BUTTONS[number]

const NAV_ROUTES: Routes<NavButtonsType> = {
	Rooms: /\/rooms(\/.*)?/,
	SNGs: /\/sngs(\/.*)?/,
	Users: /\/users/,
	Levels: /\/levels/,
	Withdrawals: /\/withdrawals/,
	Other: /\/other/
}

export default () => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const active = NAV_BUTTONS.find((it) => NAV_ROUTES[it].test(location.pathname))

	const handleButtonClick = (button: NavButtonsType) => {
		const path: string = when(button, {
			Rooms: () => '/rooms',
			SNGs: () => '/sngs',
			Users: () => '/users',
			Levels: () => '/levels',
			Withdrawals: () => '/withdrawals',
			Other: () => '/other'
		})
		navigate(path)
	}

	const handleMenuItemClick = (menuItem: MenuItemsType) => {
		whenVoid(menuItem, {
			profile: () => navigate('/profile'),
			logout: () => dispatch(logout()),
		})
	}

	return NavBar<NavButtonsType, MenuItemsType>({
		appIcon: R.images.iconBoorio,
		buttons: NAV_BUTTONS,
		active,
		onClick: handleButtonClick,
		menu: {
			items: MENU_ITEMS,
			onClick: handleMenuItemClick,
		},
        extras: [
            <CryptoWallet/>
        ]
	})
}
