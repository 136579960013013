import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentsBalanceModel, UsersDailyStatisticsModel, PokerGamesDailyStatisticsModel } from 'boorio-portal-commons/api'

export interface StatsStore {
	users: UsersDailyStatisticsModel[]
    games: PokerGamesDailyStatisticsModel[]
    payments: PaymentsBalanceModel[]
}

const initialState: StatsStore = {
    users: [],
    games: [],
    payments: []
}

export const cryptoSlice = createSlice({
	name: 'Stats',
	initialState,
	reducers: {
		updateUsers: (state, action: PayloadAction<UsersDailyStatisticsModel[]>) => {
			state.users = action.payload
		},
		updateGames: (state, action: PayloadAction<PokerGamesDailyStatisticsModel[]>) => {
			state.games = action.payload
		},
		updatePayments: (state, action: PayloadAction<PaymentsBalanceModel[]>) => {
			state.payments = action.payload
		}
	},
})

export const { updateUsers, updateGames, updatePayments } = cryptoSlice.actions

export default cryptoSlice.reducer
