import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'

export interface WithdrawalsHeaderProps {
	clickRefresh: () => void
	isRefreshing?: boolean
}

export const WithdrawalsHeader: React.FC<WithdrawalsHeaderProps> = ({ clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader title='Withdrawal Requests'>
            <RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
        </SectionHeader>
	)
}