import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DepositTransactionModel, ConversionTransactionModel, CreditTransactionModel, PaymentsBalanceModel, UnknownTransactionModel } from 'boorio-portal-commons/api'

export interface Crypto {
	balance?: PaymentsBalanceModel
    transactionsUnknown: UnknownTransactionModel[]
    depositsIgnored: DepositTransactionModel[]
    conversionsUnknown: ConversionTransactionModel[]
    creditsUnknown: CreditTransactionModel[]
}

const initialState: Crypto = {
	balance: undefined,
    transactionsUnknown: [],
    depositsIgnored: [],
    conversionsUnknown: [],
    creditsUnknown: []
}

export const cryptoSlice = createSlice({
	name: 'Crypto',
	initialState,
	reducers: {
		updateBalance: (state, action: PayloadAction<PaymentsBalanceModel>) => {
			state.balance = action.payload
		},
		updateTransactionsUnknown: (state, action: PayloadAction<UnknownTransactionModel[]>) => {
			state.transactionsUnknown = action.payload
		},
		updateDepositsIgnored: (state, action: PayloadAction<DepositTransactionModel[]>) => {
			state.depositsIgnored = action.payload
		},
		updateConversionsUnknown: (state, action: PayloadAction<ConversionTransactionModel[]>) => {
			state.conversionsUnknown = action.payload
		},
		updateCreditsUnknown: (state, action: PayloadAction<CreditTransactionModel[]>) => {
			state.creditsUnknown = action.payload
		}
	},
})

export const { updateBalance, updateTransactionsUnknown, updateDepositsIgnored, updateConversionsUnknown, updateCreditsUnknown } = cryptoSlice.actions

export default cryptoSlice.reducer
