import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export type Coordinates = [number, number]

export interface Popups {
	coords?: Coordinates
}

const initialState: Popups = {}

export const popupsSlice = createSlice({
	name: 'Popups',
	initialState,
	reducers: {
		updateCoords: (state, action: PayloadAction<Coordinates>) => {
			state.coords = action.payload
		},
	},
})

export const { updateCoords } = popupsSlice.actions

export default popupsSlice.reducer
