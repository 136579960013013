import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import AddAdminMessageForm, { AddAdminMessageFormData } from './AddAdminMessageForm'

export type AddAdminMessageViewProps = {
	clickSubmit: SubmitHandler<AddAdminMessageFormData>
} & CommonModalProps

export const AddAdminMessageView : React.FC<AddAdminMessageViewProps> = ({ id, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<AddAdminMessageForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}