import React, { useState } from 'react'
import { PokerGameModel, PokerGameSNGStatusType } from 'boorio-portal-commons/api'
import { AddAlarm, Bedtime, BedtimeOff, Edit } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import MathUtils from 'boorio-portal-commons/utils/MathUtils'

export interface SNGsListItemProps {
	room: PokerGameModel
	clickRoom: (room: PokerGameModel) => void
}

export const SNGsListItem: React.FC<SNGsListItemProps> = ({ room, clickRoom }) => {
	const [focused, setFocused] = useState(false)
	
    const prizes = room.config.sngConfig!.prizes.map(p => `$${p}`).join(' | ')

    return (
        <div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
            <div className='boo-list-info'>
                <p className='normal' style={{ marginLeft: '0px' }}>{room.config.type}</p>
                    <p className='normal' style={{ marginLeft: '0px' }}>{room.config.type}</p>
                    <p className='index'>{room.config.roomName}</p>
                    <p className='normal'>${room.config.minimumBuyIn} | ${room.config.sngConfig!.startingChips}</p>
                    <p className='normal'>{prizes}</p>
                    <p className='short'>${room.config.sngConfig!.startingSmallBlindAmount} | ${room.config.sngConfig!.startingBigBlindAmount}</p>
                    <p className='normal'>${room.config.sngConfig!.maximumSmallBlindAmount} | {room.config.sngConfig!.gamesToDoubleBlinds}</p>
                    <p className='normal'>{room.players.length}/{room.config.maxPlayers} | MIN {room.config.sngConfig!.minimumPlayers}</p>
                    <p className='normal'>{room.sngData?.status === PokerGameSNGStatusType.Playing ? <AddAlarm/> : <Bedtime/>} {room.roundData.type} | {room.turnData.type}</p>
                    <p className='normal'>${room.config.smallBlindAmount} | ${room.config.bigBlindAmount}</p>
                    <p className='short'>{room.statistics.gamesPlayed}</p>
         </div>
            <Fade in={focused}>
                <div className='boo-list-options'>
                    <BOOButton onClick={() => clickRoom(room)}>
                        <Edit />
                        &nbsp;Edit
                    </BOOButton>
                </div>
            </Fade>
        </div>
    )
}
