import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsLoggedIn } from '../../stores/hooks/useActiveUser'
import { useRequiresAuth } from '../Navigation'

export const useRedirectLoggedIn = () => {
	const requiresAuth = useRequiresAuth()
	const isLoggedIn = useIsLoggedIn()
	const navigate = useNavigate()

	useEffect(() => {
		if (!requiresAuth && isLoggedIn) {
			navigate('/rooms')
		}
	}, [requiresAuth, isLoggedIn, navigate])
}
