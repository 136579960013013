import { Remove } from '@mui/icons-material'
import React, { useEffect, useMemo, useState } from 'react'
import { BOOButton, SubSectionHeader } from 'boorio-portal-commons/components'
import { useModal } from 'stores/hooks/modalHooks'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { getBOOError } from 'utils/error/BOOError'
import { BOOConfirmationModal } from 'views/Modal/BOOConfirmationModal'
import CryptoNavBar from '../CryptoNavBar'
import { CryptoRakesHeader } from './CryptoRakesHeader'
import { useRefreshBalance, useResetRake } from '../../../stores/hooks/cryptoHooks'
import '../../../index.scss'


export default () => {
	const [isRefreshing, setRefreshing] = useState(false)
	const { balance, refreshBalance } = useRefreshBalance()

	const resetRake = useResetRake()
	const [, showResetRakeModal] = useModal('confirmResetRake')
	const { showAlert, showError } = useReduxAlerts()

	const clickRefresh = () => {
		setRefreshing(true)

		refreshBalance()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])


    const clickResetRake= () => {
		showResetRakeModal(true)
    }
	const onConfirmResetRake = () => {
		showResetRakeModal(false)
		setRefreshing(true)

        resetRake()
        .then(() => {
            showAlert({ type: 'success', message: 'Rake reset' })
        })
        .catch((err) => {
            showError(getBOOError(err))
        })
        .finally(() => setRefreshing(false))
    }

    const actions = useMemo(() => {
        return (
                <BOOButton variant='contained' onClick={clickResetRake} color='error' style={{ marginLeft: '10px' }}>
                    <Remove />
                    &nbsp;Reset Rake
                </BOOButton>
        )
    }, [])

	return (
		<>
			<CryptoNavBar/>
			<CryptoRakesHeader balance={balance} clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			<SubSectionHeader title='Actions' />
			<div id="actions" className='section'>{actions}</div>

            <BOOConfirmationModal
                id='confirmResetRake'
                title='Approve rake reset'
                description='Do you want to reset the rake? You should`ve transferred the rake amount out and only then reset!'
                okTitle='RESET'
                cancelTitle='Cancel'
                onClickOk={onConfirmResetRake}
            />
		</>
	)
}
