import React, { useEffect, useMemo, useState } from 'react'
import { SubSectionHeader } from 'boorio-portal-commons/components'
import { useDepositsIgnored } from '../../../stores/hooks/cryptoHooks'
import '../../../index.scss'
import CryptoNavBar from '../CryptoNavBar'
import { CryptoDepositListItem } from './CryptoDepositListItem'
import { CryptoDepositListHeader } from './CryptoDepositListHeader'
import { CryptoDepositsHeader } from './CryptoDepositsHeader'


export default () => {
	const [isRefreshing, setRefreshing] = useState(false)
	const { depositsIgnored, refreshDepositsIgnored } = useDepositsIgnored()

	const clickRefresh = () => {
		setRefreshing(true)

		refreshDepositsIgnored()
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

	const ignoredDepositsViews = useMemo(
		() =>
        depositsIgnored.map((it) => (
				<CryptoDepositListItem deposit={it} />
			)),
		[depositsIgnored],
	)

	return (
		<>
			<CryptoNavBar/>
			<CryptoDepositsHeader clickRefresh={clickRefresh} isRefreshing={isRefreshing} />
			
			<SubSectionHeader title='Ignored Deposits' />
			<div className='boo-list'>
				<CryptoDepositListHeader />
				{ignoredDepositsViews}
			</div>
		</>
	)
}
