import React, { useState } from 'react'
import { ConversionTransactionModel } from 'boorio-portal-commons/api'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import { Redo } from '@mui/icons-material'

export interface CryptoConversionListItemProps {
	conversion: ConversionTransactionModel,
	clickRetry: (conversion: ConversionTransactionModel) => void
}

export const CryptoConversionListItem: React.FC<CryptoConversionListItemProps> = ({ conversion, clickRetry }) =>
{
	const [focused, setFocused] = useState(false)

	return (
        <div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
			<div className='boo-list-info'>
				<a href={`https://bscscan.com/tx/${conversion.id}`}><p className='index'>{conversion.id}</p></a>
				<a href={`https://bscscan.com/tx/${conversion.depositId}`}><p className='wide'>{conversion.depositId}</p></a>
				<a href={`https://bscscan.com/address/${conversion.depositFromAddress}`}><p className='normal'>{conversion.depositFromAddress}</p></a>
                <div className='spacer' />
            </div>
            <Fade in={focused}>
                <div className='boo-list-options'>
                    <BOOButton onClick={() => clickRetry(conversion)}>
                        <Redo />
                        &nbsp;Retry
                    </BOOButton>
                </div>
            </Fade>
        </div>
	)
}