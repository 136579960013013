import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AdminApi, PokerGameFormatType, PokerGameModel } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { refreshRooms, updateRoom } from 'stores/features/RoomsStore'
import { RootState } from '../AppStore'

export const useRefreshRooms = (format: PokerGameFormatType) => {
	const rooms = useSelector((state: RootState) => state.rooms.rooms.filter(r => r.config.format === format))
	const dispatch = useDispatch()
	const { showAlert } = useReduxAlerts()
	const api = useApi(AdminApi)

	const action = useCallback(async () =>
    {
        try {
            const rooms = (await api.adminRoomsGetAll()).data
            dispatch(refreshRooms(rooms))
        } catch (e: any) {
            showAlert({ type: 'error', message: e?.message ?? e })
        }
    }, [api])

	return { rooms, refresh: action }
}

export const useGetRoomById = (id: string): PokerGameModel | undefined => {
	const rooms = useSelector((state: RootState) => state.rooms.rooms)
	return rooms.find((it) => it.id === id)
}

export const useRefreshRoom = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (roomId: string) =>
    {
        const room = (await api.adminRoomsGet(roomId)).data
        dispatch(updateRoom(room))
    },
    [api])

	return action
}

export const useKickRoom = () => {
	const dispatch = useDispatch()
	const api = useApi(AdminApi)

	const action = useCallback(async (roomId: string) =>
    {
        const room = (await api.adminsUtilsKickPlayers(roomId)).data
        dispatch(updateRoom(room))
	},
    [api])
    
	return action
}