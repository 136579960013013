import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AdminApi, UserLevelRequirementModel } from 'boorio-portal-commons/api'
import { useApi } from 'utils/api/useApi'
import { useReduxAlerts } from 'views/Alerts/ReduxAlertStack'
import { BOOConfirmationModal } from '../../views/Modal/BOOConfirmationModal'
import { useLevelRequirements } from '../../stores/hooks/levelRequirementsHooks'
import { useModal } from '../../stores/hooks/modalHooks'
import { getBOOError } from '../../utils/error/BOOError'
import { BOOModal } from '../../views/Modal/BOOModal'
import EditLevelForm, { EditLevelFormData } from './Edit/EditLevelForm'
import '../../index.scss'
import { LevelRequirementListHeader } from './LevelRequirementListHeader'
import { LevelRequirementListItem } from './LevelRequirementListItem'
import { LevelRequirementsHeader } from './LevelRequirementsHeader'

export default () => {
	const api = useApi(AdminApi)
	const { showAlert } = useReduxAlerts()
	const [, showDeleteModal] = useModal('confirmDeletion')
	const [, showEditModal] = useModal('editLevel')
	const [selectedLevel, setLevel] = useState<UserLevelRequirementModel | undefined>()
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [editMode, setEditMode] = useState<'create' | 'edit' | undefined>(undefined)
	const { levels, refreshLevels } = useLevelRequirements()
	const [refreshing, setRefreshing] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)
		refreshLevels().finally(() => {
			setRefreshing(false)
		})
	}

	useEffect(() => {
		clickRefresh()
	}, [])

	const onClickCreate = () => {
		setEditMode('create')
		showEditModal(true)
	}

	const onClickDelete = (level: UserLevelRequirementModel) => {
		setLevel(level)
		showDeleteModal(true)
	}

	const onConfirmDelete = () => {
		showDeleteModal(false)
		showAlert({ type: 'error', message: 'Not Implemented in BE yet' })
		setLevel(undefined)
	}

	const onClickEdit = (level: UserLevelRequirementModel) => {
		setEditMode('edit')
		setLevel(level)
		showEditModal(true)
	}

	const onSubmitEdit: SubmitHandler<EditLevelFormData> = (data) => {
		setEditModalLoading(true)
		api
			.levelRequirementsUpdate(data.level!, data as UserLevelRequirementModel)
			.then(refreshLevels)
			.then(() => {
				showAlert({ type: 'success', message: 'Level edited successfully!' })
			})
			.catch((err) => {
				const booErr = getBOOError(err)
				showAlert({ type: 'error', message: booErr.message })
				showEditModal(false)
			})
			.finally(() => {
				showEditModal(false)
			})
	}

	const onCancelEdit = () => {
		showEditModal(false)
		setLevel(undefined)
	}

	const items = levels.map((it) => <LevelRequirementListItem key={it.level} level={it} onClickDelete={onClickDelete} onClickEdit={onClickEdit} />)

	const editModal = useMemo(() => {
		const common = {
			onSubmit: onSubmitEdit,
			onCancel: onCancelEdit,
			loading: editModalLoading,
		}
		if (editMode === 'create') {
			return (
				<BOOModal id='editLevel'>
					<EditLevelForm mode='create' {...common} />
				</BOOModal>
			)
		}
		if (editMode === 'edit' && selectedLevel) {
			return (
				<BOOModal id='editLevel'>
					<EditLevelForm mode='edit' level={selectedLevel} {...common} />{' '}
				</BOOModal>
			)
		}
		return null
	}, [editMode, selectedLevel, editModalLoading])

	return (
		<>
			{editModal}
			<BOOConfirmationModal
				id='confirmDeletion'
				title={`Delete level ${selectedLevel?.level}`}
				description={`Do you want to delete the level ${selectedLevel?.level}?`}
				okTitle='Yes, delete'
				cancelTitle='Cancel'
				onClickOk={onConfirmDelete}
			/>
            
            <LevelRequirementsHeader clickRefresh={clickRefresh} isRefreshing={refreshing} />
			<div className='boo-list'>
				<LevelRequirementListHeader onClickAdd={onClickCreate} />
				{items}
			</div>
		</>
	)
}
