import { FC } from 'react'
import { useModal } from '../../stores/hooks/modalHooks'

interface ModalRequiredProps {
	id: string
	open: boolean
	onDismiss?: () => void
	close: () => void
}

type ModalProps<T> = ModalRequiredProps & T

export type ReduxModalModalProps<T> = Omit<ModalProps<T>, 'open' | 'close'>

type ReduxModalProps<T> = {
	modal: FC<ModalProps<T>>
} & ReduxModalModalProps<T>

export function ReduxModal<T>(props: ReduxModalProps<T>) {
	const { modal, ...modalProps } = props
	const [open, setOpen] = useModal(modalProps.id)
	const close = () => setOpen(false)

	return props.modal({
		...modalProps,
		open,
		close,
	} as ModalRequiredProps & T)
}
