import React, { useEffect, useState } from 'react'
import { useGetBotDatas, useRefreshBots, useRefreshBotWallets } from '../../../stores/hooks/usersHooks'
import { BotsListItem } from './BotsListItem'
import { BotsListHeader } from './BotsListHeader'
import { BotsHeader } from './BotsHeader'
import '../../../index.scss'

export default () => {
	const { botDatas } = useGetBotDatas()
	const { refreshBots } = useRefreshBots()
	const { refreshBotWallets } = useRefreshBotWallets()
	const [isRefreshing, setRefreshing] = useState(false)

	const clickRefresh = () => {
		setRefreshing(true)

        refreshBots()
		.then(() => refreshBotWallets())
        .finally(() => setRefreshing(false))
	}

	useEffect(() => {
        clickRefresh()
	}, [])

    const usersList = Object.entries(botDatas)
	const items = usersList.map(([userId, userData]) => <BotsListItem key={userId} user={userData.user} wallet={userData.wallet} />)

	return (
		<>
			<BotsHeader clickRefresh={clickRefresh} isRefreshing={isRefreshing} usersCount={usersList.length} />
			<div className='boo-list'>
				<BotsListHeader />
				{items}
			</div>
		</>
	)
}
