import React, { useMemo } from 'react'
import { PokerGameModel } from 'boorio-portal-commons/api'
import { SubSectionHeader } from 'boorio-portal-commons/components/section/SubsectionHeader'
import { BOOButton } from 'boorio-portal-commons/components/controls/buttons/BOOButton'
import { Add, Remove, Stop, Visibility, VisibilityOff } from '@mui/icons-material'
import { JsonView } from 'boorio-portal-commons/components'
import { RoomHeader } from './RoomHeader'
import { PlayerView } from './PlayerView'
import { AddBotView, AddBotViewProps } from './AddBot/AddBotView'
import { ChangeNameView, ChangeNameViewProps } from './ChangeName/ChangeNameView'
import { RoomDeleteView, RoomDeleteViewProps } from './Delete/RoomDeleteView'
import { RoomKickPlayersView, RoomKickPlayersViewProps } from './KickPlayers/RoomKickPlayersView'

export interface RoomPageViewProps {
	room: PokerGameModel,
	clickRefresh: () => void,
	isRefreshing: boolean,
	
	changeHidden: (hidden: boolean) => void,
	addBotProps: AddBotViewProps,
	changeNameProps: ChangeNameViewProps,
	roomKickPlayersProps: RoomKickPlayersViewProps,
	roomDeleteProps: RoomDeleteViewProps
}

export const RoomPageView : React.FC<RoomPageViewProps> = ({ room, clickRefresh, isRefreshing, changeHidden, addBotProps, changeNameProps, roomKickPlayersProps, roomDeleteProps }) =>
{
    const actions = useMemo(() => {
        return (
            <>
                <BOOButton variant='contained' onClick={() => addBotProps.clickOpen(true)}>
					<Add />
					&nbsp;Add Bot
				</BOOButton>
				<BOOButton variant='contained' onClick={() => changeNameProps.clickOpen(true)} style={{ marginLeft: '10px' }}>
					&nbsp;Change Name
				</BOOButton>
                <BOOButton variant='contained' onClick={() => roomKickPlayersProps.clickOpen(true)} color='error' style={{ marginLeft: '10px' }}>
                    <Stop />
                    &nbsp;Kick + Refund
                </BOOButton>
                <BOOButton variant='contained' onClick={() => changeHidden(!room.status.hidden)} color='info' style={{ marginLeft: '10px' }}>
                    {room.status.hidden ? <><Visibility />&nbsp;Unhide</> : <><VisibilityOff />&nbsp;Hide</>}
                </BOOButton>
                <BOOButton variant='contained' onClick={() => roomDeleteProps.clickOpen(true)} color='error' style={{ marginLeft: '10px' }}>
                    <Remove />
                    &nbsp;Delete
                </BOOButton>
            </>
        )
    }, [room.status.hidden])

	const players = room.players.map((it) => <PlayerView key={it.profile.userId} player={it} />)

	return (
		<>
            {room && <RoomHeader room={room} clickRefresh={clickRefresh} isRefreshing={isRefreshing} /> }
			<SubSectionHeader title='Actions' />
            <div id="actions" className='section'>{actions}</div>
            
            <SubSectionHeader title='Players'/>
			<div className='boo-list'>{players}</div>

            <SubSectionHeader title='Raw'/> 
			{room && <JsonView name='Room' object={room} />}

			<AddBotView {...addBotProps} />
			<ChangeNameView {...changeNameProps} />
			<RoomKickPlayersView {...roomKickPlayersProps} />
			<RoomDeleteView {...roomDeleteProps} />
		</>
	)
}
