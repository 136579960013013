import React from 'react'
import { RefreshButton, SectionHeader } from 'boorio-portal-commons/components'
import { User } from 'boorio-portal-commons/api'

export interface UserHeaderProps {
    user: User
	clickRefresh: () => void
	isRefreshing?: boolean
}

export const UserHeader: React.FC<UserHeaderProps> = ({ user, clickRefresh, isRefreshing }) => {
	const refreshing = isRefreshing ?? false

	return (
        <SectionHeader
            title={user.info.name}
            preTitle={<img id='user-avatar' className='big' src={user.info.avatar} alt='avatar' style={{ marginRight: '20px' }} />}
        >
			<RefreshButton variant='text' loading={refreshing} onClick={clickRefresh} />
		</SectionHeader>
	)
}