import React from 'react'
import { PokerGameModel } from 'boorio-portal-commons/api'

export type SNGRunningGameViewProps = {
	game: PokerGameModel
}

export const SNGRunningGameView: React.FC<SNGRunningGameViewProps> = ({ game }) => {
	return (
		<div className='playerview-card'>
			<p style={{ width: '10%' }}>
                #Games Played: {game.sngData!.gamesPlayed}
            </p>
			<p style={{ width: '10%' }}>
                SB ${game.config.smallBlindAmount} | ${game.config.bigBlindAmount} BB
            </p>
			<p style={{ width: '10%' }}>
                Current Pot: ${game.roundData.tablePotAmount}
            </p>
			<p style={{ width: '10%' }}>
                Turn: {game.turnData.type}
            </p>
		</div>
	)
}
