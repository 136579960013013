import React, { useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { BOOButton } from 'boorio-portal-commons/components'
import { UserLevelRequirementModel, UserLevelRequirementType } from 'boorio-portal-commons/api'

type UserFriendlyUserLevelType = {
	[key in UserLevelRequirementType]: string
}

const userFriendlyUserLevel: UserFriendlyUserLevelType =
{
	HANDSPLAYED: 'hands played',
	HANDSWON: 'hands won',
	SPLITPOTSWON: 'splitpots won',
}

export interface LevelRequirementListItemProps
{
	level: UserLevelRequirementModel
	onClickDelete: (level: UserLevelRequirementModel) => void
	onClickEdit: (level: UserLevelRequirementModel) => void
}

export const LevelRequirementListItem: React.FC<LevelRequirementListItemProps> = ({ level, onClickDelete, onClickEdit }) => {
	const [focused, setFocused] = useState(false)
	return (
		<div className='boo-list-item' onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
			<div className='boo-list-info'>
				<p className='index'>{level.level}</p>
				<p className='wide'>{`${level.required} ${userFriendlyUserLevel[level.type]}`}</p>
			</div>
			<Fade in={focused}>
				<div className='boo-list-options'>
					<BOOButton onClick={() => onClickEdit(level)}>
						<Edit />
						&nbsp;Edit
					</BOOButton>
					<BOOButton onClick={() => onClickDelete(level)} color='error'>
						<Delete />
						&nbsp;Delete
					</BOOButton>
				</div>
			</Fade>
		</div>
	)
}