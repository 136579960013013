import React from 'react'
import { BOOModal } from 'views/Modal/BOOModal'
import { SubmitHandler } from 'react-hook-form'
import { CommonModalProps } from 'views/Modal/CommonModalProps'
import { LocalisationsModel } from 'boorio-portal-commons/api'
import UpdateLocalisationForm, { UpdateLocalisationFormData } from './UpdateLocalisationForm'

export type UpdateLocalisationViewProps = {
	localisations: LocalisationsModel,
	clickSubmit: SubmitHandler<UpdateLocalisationFormData>
} & CommonModalProps

export const UpdateLocalisationView : React.FC<UpdateLocalisationViewProps> = ({ id, localisations, clickSubmit, clickOpen, loading }) =>
{
	return (
		<BOOModal id={id}>
			<UpdateLocalisationForm loading={loading} onSubmit={clickSubmit} onCancel={() => clickOpen(false)} />
		</BOOModal>
	)
}