import { Tooltip } from '@mui/material'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserByReferralCode } from '../../stores/hooks/usersHooks'
import './Tooltip.scss'

export const UserRefTooltip = ({ referralCode, children } : { referralCode: string; children: ReactElement<any, any> }) => {
	const navigate = useNavigate()
	const { user } = useUserByReferralCode(referralCode)

	const handleClick = useCallback(() => {
		if (user){
			navigate(`/users/${user.id}`)
		}
	}, [])

	const title = useMemo(() => {
		if (!user) { return 'Unknown' }
		return (
			<>
                <img id='user-avatar' src={user?.info.avatar} alt='avatar' />
				<p>Name: {user?.info.name}</p>
				<p>Id: {user?.id}</p>
			</>
		)
	}, [user])

	return (
		<Tooltip className='tooltip' title={title} placement='top' arrow onClick={handleClick}>
			{children}
		</Tooltip>
	)
}
