import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminLoginResponse } from 'boorio-portal-commons/api'

export interface ActiveUserState {
	user?: AdminLoginResponse
	loading: boolean
	error?: any | undefined
}

type LoginPayload = AdminLoginResponse

const initialState: ActiveUserState = {
	loading: false,
}

export const activeUserSlice = createSlice({
	name: 'ActiveUser',
	initialState,
	reducers: {
		logout: (state) => initialState,
		login: (state, action: PayloadAction<LoginPayload>) => {
			state.user = action.payload
			state.loading = false
			state.error = undefined
		},
		setLoggingIn: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setError: (state, action: PayloadAction<any | undefined>) => {
			state.error = action.payload
			state.loading = false
		},
	},
})

export const { logout, login, setLoggingIn, setError } = activeUserSlice.actions

export default activeUserSlice.reducer
