import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../AppStore'
import { Event, pushEvent, removeEvent } from '../features/EventsReducer'

export const useEvents = () => {
	const dispatch = useDispatch()
	const events = useSelector((root: RootState) => root.events.events)

	const show = useCallback(
		(event: Event) => {
			const newEvent = {
				duration: 3,
				...event,
			}
			dispatch(pushEvent(newEvent))
		},
		[dispatch],
	)

	const remove = useCallback(
		(event: Event) => {
			dispatch(removeEvent(event))
		},
		[dispatch],
	)

	return { events, showAlert: show, removeAlert: remove }
}
